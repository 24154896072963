import {
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import moment from "moment";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import BannerImageUpload from "../../components/BannerImageUpload";
const bannerPositions = [
  { name: "Top banner", label: lang("Top Banner") },
  // { name: "Mid banner", label: "Mid Banner" },
  { name: "Bottom banner", label: lang("Bottom Banner") },
];

const FileType = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/avif",
  "image/webp",
  "image/gif",
];

const AdvertisementBannerForm = ({ section, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const { country, cities } = useAppContext();

  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState();
  const [file, setFile] = useState();

  const [mobileNumber, setMobileNumber] = useState();
  const [categories, setCategories] = useState();
  const [restaurants, setRestaurants] = useState([]);
  const [bannerFor, setBannerFor] = useState();
  const [bannerType, setBannerType] = useState();
  // const [cities, setCities] = useState([]);

  const handleImage = (data) => {
    data.length > 0 ? setImage(data[0].url) : setImage();
  };

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };

  // const getCities = (id, page, search = false) => {
  //   if (!id) return;
  //   request({
  //     url: `/country-city/${id}?page=${page}&pageSize=2000${
  //       search ? `&search=${search}` : ""
  //     }`,
  //     method: "GET",
  //     onSuccess: ({ data, status }) => {
  //       console.log(data, "setCities");
  //       if (data) {
  //         setCities((prev) => [...data]);
  //         //search ? setCities(prev => ([...data])) : setCities(prev => ([...prev, ...data]));
  //       }
  //     },
  //   });
  // };

  useEffect(() => {
    if (!country.country_id) return;
    // setCities([]);
    // getCities(country.country_id, 1);
  }, [country.country_id]);

  const onCreate = (values) => {
    const { city_ids, city_id } = values;

    let payload = {
      ...values,
      image: image,
      city_ids: bannerFor == "company" ? city_ids : [city_id],
    };

    if (mobileNumber) {
      payload = { ...payload, ...mobileNumber };
    }

    console.log(payload, "payload");

    setLoading(true);

    request({
      url: data ? apiPath.banner + "/" + data._id : apiPath.banner,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const getRestaurant = (city) => {
    request({
      url: apiPath.adminCommon + `/restaurants${city ? `?city=${city}` : ""}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setRestaurants(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    if (!!data) {
      form.setFieldsValue({
        ...data,
        start_date: moment(data.start_date),
        end_date: moment(data.end_date),
        restaurant_id: data.restaurant_id?._id,
        offer_id: data.offer_id?._id,
        mobile_number: data.country_code
          ? data.country_code + data.mobile_number
          : "",
      });
      getRestaurant(data.city_id);
      setImage(data.image);
      setBannerFor(data.banner_for);
      setBannerType(data.type);
      setFile(data.image);

      if (data.country_code) {
        setMobileNumber({
          country_code: data.country_code,
          mobile_number: data.mobile_number,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    // getRestaurant()
  }, []);

  const startDate = Form.useWatch("start_date", form);

  return (
    <Modal
      width={750}
      open={show}
      okText={data ? lang("Update") : lang("Add")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal"
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <h4 className="modal_title_cls">
          {data ? lang("Edit Banner") : lang("Add Banner")}
        </h4>
        <Row gutter={[16, 0]} className="w-100">
          <Col span={24}>
            <Form.Item
              className=""
              rules={[
                {
                  validator: (_, value) => {
                    if (image) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(lang("Image is required")));
                  },
                },
              ]}
              label={lang("Upload Banner Image")}
              name="image"
            >
              <BannerImageUpload
                value={image}
                fileType={FileType}
                imageType="advertisement"
                onChange={(data) => handleImage(data)}
                isDimension={true}
              />
              <p className="img-size-details">
                **
                {lang(
                  "Images should be 600x400 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size",
                )}
                ..!!!
              </p>
              {
                <div className="mt-2">
                  <Image width={120} src={image ? image : notfound}></Image>
                </div>
              }
            </Form.Item>
          </Col>

          <Col span={24} sm={24} md={12}>
            <Form.Item
              name="banner_for"
              label={lang("Create Banner For")}
              rules={[
                {
                  required: true,
                  message: lang("Please Select Banner For"),
                },
              ]}
            >
              <Select
                placeholder={lang("Select Banner For")}
                className="w-100"
                onChange={(value) => setBannerFor(value)}
              >
                <option value={"company"}>
                  <span className="cap">{lang("Outside company")}</span>
                </option>
                <option value={"app"}>
                  <span className="cap">{lang("Inside App")}</span>
                </option>
              </Select>
            </Form.Item>
          </Col>

          {bannerFor == "company" ? (
            <>
              <Col span={24} sm={24} md={12}>
                <Form.Item
                  label={lang("Advertiser Company Name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: lang("Please Enter Company Name"),
                    },
                    {
                      max: 40,
                      message: lang(
                        "Name should not contain more then 40 characters!",
                      ),
                    },
                    {
                      min: 2,
                      message: lang(
                        "Name should contain at least 2 characters!",
                      ),
                    },
                  ]}
                  normalize={(value) => value.trimStart()}
                >
                  <Input
                    autoComplete="off"
                    placeholder={lang("Enter Advertiser Company Name")}
                  />
                </Form.Item>
              </Col>

              <Col span={24} md={12}>
                <Form.Item
                  className="mb-3"
                  label={lang("Phone Number")}
                  name="mobile_number"
                  rules={[
                    {
                      required: true,
                      message: lang("Please Enter Phone Number"),
                    },
                  ]}
                >
                  <PhoneInput
                    inputProps={{
                      name: "mobile_number",
                      required: true,
                      autoFocus: false,
                      placeholder: "Enter Phone Number",
                    }}
                    isValid={(value, country) => {
                      if (value.match(/1234/)) {
                        return "Invalid value: " + value + ", " + country.name;
                      } else if (value.match(/1234/)) {
                        return "Invalid value: " + value + ", " + country.name;
                      } else {
                        return true;
                      }
                    }}
                    country={"il"}
                    preferredCountries={["ps", "il"]}
                    onChange={handleMobileNumberChange}
                  />
                </Form.Item>
              </Col>

              <Col span={12} sm={24} md={12}>
                <Form.Item
                  label={lang("Website Link")}
                  name="link"
                  rules={[
                    {
                      required: true,
                      message: lang("Please Enter Company Link"),
                    },
                    {
                      type: "url",
                      message: lang("Please enter a valid URL"),
                    },
                  ]}
                  normalize={(value) => value.trimStart()}
                >
                  <Input
                    autoComplete="off"
                    placeholder={"Enter Website Link"}
                  />
                </Form.Item>
              </Col>

              <Col span={24} sm={24} md={12}>
                <Form.Item
                  name="city_ids"
                  label={lang("Select Target City")}
                  rules={[
                    {
                      required: true,
                      message: lang("Please Select City"),
                    },
                  ]}
                >
                  <Select
                    className="restaurant-selected"
                    mode="multiple"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder={lang("Select City")}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={cities.map((item) => ({
                      value: item._id,
                      label: item.name,
                    }))}
                    // onChange={(value) => getRestaurant(value)}
                  />
                </Form.Item>
              </Col>
            </>
          ) : null}

          {bannerFor == "app" ? (
            <>
              <Col span={24} sm={24} md={12}>
                <Form.Item
                  name="type"
                  label={lang("Select Banner Type")}
                  rules={[
                    {
                      required: true,
                      message: lang("Please Select Banner Type"),
                    },
                  ]}
                >
                  <Select
                    placeholder={lang("Select Banner Type")}
                    className="w-100"
                    onChange={(value) => setBannerType(value)}
                  >
                    <option value={"restaurant"}>
                      <span className="cap">{"Restaurant"}</span>
                    </option>
                    {/* <option value={'offer'}>
                      <span className="cap">Offer</span>
                    </option> */}
                  </Select>
                </Form.Item>
              </Col>

              {bannerType == "restaurant" ? (
                <>
                  <Col span={24} sm={24} md={12}>
                    <Form.Item
                      name="city_id"
                      label={lang("Select City")}
                      rules={[
                        {
                          required: true,
                          message: lang("Please Select City"),
                        },
                      ]}
                    >
                      <Select
                        placeholder={lang("Select City")}
                        showSearch
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={cities.map((item) => ({
                          value: item._id,
                          label: item.name,
                        }))}
                        onChange={(value) => getRestaurant(value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} sm={24} md={12}>
                    <Form.Item
                      name="restaurant_id"
                      label={lang("Select Restaurant")}
                      rules={[
                        {
                          required: true,
                          message: lang("Please Select Restaurant"),
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        placeholder="Select Restaurant"
                        className="w-100"
                      >
                        {restaurants.map((item, index) => (
                          <option key={item._id} value={item._id}>
                            <span className="cap">{item.name}</span>
                          </option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              ) : null}
            </>
          ) : null}

          {/* <Col span={24} sm={24} md={12}>
            <Form.Item
              name="category_id"
              label="Company Category"
              rules={[
                {
                  required: true,
                  message: "Please Select Company Category",
                },
              ]}
            >
              <Select placeholder="Select Company Category" className="w-100">
                {categories && categories && categories.length > 0
                  ? categories.map((item, index) => (
                    <option key={item._id} value={item._id}>
                      <span className="cap">{item.name}</span>
                    </option>
                  ))
                  : null}
              </Select>
            </Form.Item>
          </Col> */}

          <Col span={12} sm={24} md={12}>
            <Form.Item
              label={`${lang("Price")} (${country.data?.currency})`}
              name="price"
              rules={[
                {
                  required: true,
                  message: lang("Please Enter Price"),
                },
              ]}
            >
              <InputNumber
                autoComplete="off"
                placeholder={lang("Enter Price")}
              />
            </Form.Item>
          </Col>

          <Col span={12} md={12}>
            <Form.Item
              label={lang("Start Date")}
              name="start_date"
              rules={[
                {
                  required: true,
                  message: lang("Please select the start date"),
                },
              ]}
            >
              <DatePicker
                placeholder={lang("Select Start Date")}
                disabledDate={(current) =>
                  current && current < moment().endOf("day")
                }
              />
            </Form.Item>
          </Col>

          <Col span={12} md={12}>
            <Form.Item
              label={lang("End Date")}
              name="end_date"
              dependencies={["start_date"]}
              rules={[
                {
                  required: true,
                  message: lang("Please select the end date"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("start_date") > value) {
                      return Promise.reject(
                        lang("End date is not less than start date"),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                placeholder={lang("Select End Date")}
                disabledDate={(current) =>
                  current && current < moment().endOf("day")
                }
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={24} md={12}>
            <Form.Item
              name="position"
              label={lang("Banner Position")}
              rules={[
                {
                  required: true,
                  message: lang("Please select the Banner Position"),
                },
              ]}
            >
              <Select
                placeholder={lang("Select Banner Position")}
                className="w-100"
              >
                {bannerPositions.map((item, index) => (
                  <option key={item.name} value={item.name}>
                    <span className="cap">{item.label}</span>
                  </option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AdvertisementBannerForm;
