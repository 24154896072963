import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Upload, message, Checkbox } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { uploadFile } from "react-s3";
import { s3Config } from "../../config/s3Config";

import notfound from "../../assets/images/not_found.png";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
const { Option } = Select;

function CreateAsset({ data }) {
    const sectionName = "Create Asset";
    const heading = " Asset Management";

    const api = {
        add: apiPath.addAsset,
        edit: apiPath.editAsset
    };
    const [form] = Form.useForm();
    const { setPageHeading } = useContext(AppStateContext);
    const { request } = useRequest();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [image, setImage] = useState([]);

    const FileType = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/avif",
        "image/webp",
        "image/gif",
    ];

    const beforeUpload = (file) => {
        if (FileType.includes(file.type)) {
        } else {
            message.error("File format is not correct");
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error(`Image must be smaller than 5 MB!`);
            return false;
        }
        return true;
    };

    const onCreate = (values) => {
        const payload = {
            ...values,
            location: {
                city: values.city,
                neighborhood: values.neighborhood,
                street: values.street,
                street_number: values.street_number
            },
            image: image,
        };

        setLoading(true);
        request({
            url: `${data ? api.add + "/" + data._id : api.edit}`,
            method: data ? "PUT" : "POST",
            data: payload,

            onSuccess: (data) => {
                setLoading(false);
                if (data?.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                    navigate(-1);
                } else {
                    ShowToast(data.message, Severty.ERROR);
                }
            },
            onError: (error) => {
                ShowToast(error.response?.data.message, Severty.ERROR);
                setLoading(false);
            },
        });
    };

    useEffect(() => {
        if (!data) return;
        const formValues = {
            ...data,
            ...data.location,
            date_of_entry: data.date_of_entry ? moment(data.date_of_entry) : null
        };
        form.setFieldsValue(formValues);
        if (data.image != undefined) {
            setImage(data.image);
        } else {
            setImage([notfound]);
        }
        console.log(data);
    }, [data]);

    useEffect(() => {
        setPageHeading(heading);
    }, [setPageHeading]);

    return (
        <>
            <Card title={sectionName}>
                <Form
                    className="edit-page-wrap"
                    form={form}
                    onFinish={onCreate}
                    autoComplete="off"
                    layout="vertical"
                    name="asset_form"
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Asset Status"
                                name="asset_status"
                                rules={[{ required: true, message: "Please enter the asset status" }]}
                            >
                                <Input placeholder="Enter Asset Status" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="City"
                                name="city"
                                rules={[{ required: true, message: "Please enter the city" }]}
                            >
                                <Input placeholder="Enter City" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Neighborhood"
                                name="neighborhood"
                                rules={[{ required: true, message: "Please enter the neighborhood" }]}
                            >
                                <Input placeholder="Enter Neighborhood" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Street"
                                name="street"
                                rules={[{ required: true, message: "Please enter the street" }]}
                            >
                                <Input placeholder="Enter Street" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Street Number"
                                name="street_number"
                                rules={[{ required: true, message: "Please enter the street number" }]}
                            >
                                <Input placeholder="Enter Street Number" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Property Type"
                                name="property_type"
                                rules={[{ required: true, message: "Please enter the property type" }]}
                            >
                                <Input placeholder="Enter Property Type" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Rooms Number"
                                name="rooms_number"
                                rules={[{ required: true, message: "Please enter the number of rooms" }]}
                            >
                                <Input type="number" placeholder="Enter Rooms Number" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Floor Number"
                                name="floor_number"
                                rules={[{ required: true, message: "Please enter the floor number" }]}
                            >
                                <Input type="number" placeholder="Enter Floor Number" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Floors In Building"
                                name="floors_in_building"
                                rules={[{ required: true, message: "Please enter the number of floors in the building" }]}
                            >
                                <Input type="number" placeholder="Enter Floors In Building" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Size in Meters"
                                name="size_in_meters"
                                rules={[{ required: true, message: "Please enter the size in meters" }]}
                            >
                                <Input type="number" placeholder="Enter Size in Meters" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Property Status"
                                name="property_status"
                                rules={[{ required: true, message: "Please select the property status" }]}
                            >
                                <Select placeholder="Select Property Status">
                                    <Option value="Active">Active</Option>
                                    <Option value="Inactive">Inactive</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Price Per Square Meter"
                                name="price_per_square_meter"
                                rules={[{ required: true, message: "Please enter the price per square meter" }]}
                            >
                                <Input type="number" placeholder="Enter Price Per Square Meter" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Total Price"
                                name="total_price"
                                rules={[{ required: true, message: "Please enter the total price" }]}
                            >
                                <Input type="number" placeholder="Enter Total Price" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Property Title"
                                name="property_title"
                                rules={[{ required: true, message: "Please enter the property title" }]}
                            >
                                <Input placeholder="Enter Property Title" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Property Description"
                                name="property_description"
                                rules={[{ required: true, message: "Please enter the property description" }]}
                            >
                                <Input.TextArea placeholder="Enter Property Description" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Advertiser Name"
                                name="advertiser_name"
                                rules={[{ required: true, message: "Please enter the advertiser name" }]}
                            >
                                <Input placeholder="Enter Advertiser Name" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Advertiser Phone"
                                name="advertiser_phone"
                                rules={[{ required: true, message: "Please enter the advertiser phone" }]}
                            >
                                <Input placeholder="Enter Advertiser Phone" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Make Public"
                                name="make_public"
                                valuePropName="checked"
                            >
                                <Checkbox>Make Public</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Date of Entry"
                                name="date_of_entry"
                                rules={[{ required: true, message: "Please select the date of entry" }]}
                            >
                                <DatePicker format="YYYY-MM-DD" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={8}>
                            <Form.Item
                                label="Taxes"
                                name="taxes"
                                rules={[{ required: true, message: "Please enter the taxes" }]}
                            >
                                <Input type="number" placeholder="Enter Taxes" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={24}>
                            <Form.Item label="Amenities" name="amenities">
                                <Checkbox.Group>
                                    <Row>
                                        <Col span={8}><Checkbox value="elevator">Elevator</Checkbox></Col>
                                        <Col span={8}><Checkbox value="air_conditioning">Air Conditioning</Checkbox></Col>
                                        <Col span={8}><Checkbox value="parking">Parking</Checkbox></Col>
                                        <Col span={8}><Checkbox value="balcony">Balcony</Checkbox></Col>
                                        <Col span={8}><Checkbox value="bars">Bars</Checkbox></Col>
                                        <Col span={8}><Checkbox value="dimension">Dimension</Checkbox></Col>
                                        <Col span={8}><Checkbox value="warehouse">Warehouse</Checkbox></Col>
                                        <Col span={8}><Checkbox value="handicap_accessibility">Handicap Accessibility</Checkbox></Col>
                                        <Col span={8}><Checkbox value="furniture">Furniture</Checkbox></Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Form.Item className="btn-row float-right">
                        <Button type="primary" onClick={() => navigate(-1)} className='btn_primary'>Back</Button>
                        <Button type="primary" loading={loading} htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
}

export default CreateAsset;
