import { Button, Col, Image, Modal, Row, Divider, Steps, Rate } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import notfound from "../../assets/images/not_found.png";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { capitalize, dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";

export const ApproveStatus = {
  REJECT: "rejected",
  ACCEPT: "accepted",
  SUSPENDED: "suspended",
  PENDING: "pending",
};

const OrderLogs = ({ show, hide, data }) => {
  const { request } = useRequest();

  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  const getLogs = () => {
    setLoading(true);
    request({
      url: apiPath.order + "/logs/" + data?._id,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setLogs(data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    getLogs();
  }, [data]);

  return (
    <Modal
      open={show}
      width={750}
      okText="User"
      onCancel={hide}
      footer={[
        // <Button
        //   key="reject"
        //   onClick={() => {
        //     handleApproveReject(ApproveStatus.REJECT);
        //   }}
        // >
        //   Reject
        // </Button>,
        <Button key="approve" type="primary" onClick={hide}>
          {lang("Okay")}
        </Button>,
      ]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal driver-modal"
    >
      {/* <div className="modal_title_wrap">
         <h4 className="modal_title_cls">New Driver request</h4>
          <p>New delivery agent has been registered with the app.</p>
      </div> */}
      {/* <div className="driver_img">
        <Image
          src={data?.image ? data?.image : notfound}
          className="table-img"
          style={{ width: "70px", height: "70px" }}
        />
      </div> */}
      <Row>

        <Col span={24} md={24}>
          <div className="order-head">
            <h4>{data?.restaurant_id?.name}</h4>
            <span>
              <Rate
                disabled
                allowHalf
                value={
                  data?.restaurant_id?.rating ? data?.restaurant_id?.rating : 0
                }
              />
              <span className="no-rating">
                {data?.restaurant_id?.rating
                  ? parseFloat(data.restaurant_id.rating).toFixed(1)
                  : 0}
              </span>
              {" (" +
                (data?.restaurant_id?.review_count
                  ? data?.restaurant_id.review_count
                  : 0) +
                `(${lang("Reviews")}))`}
            </span>
            <p className="orderid">Order ID : {data?.uid}</p>
            <p className="cap">{dateString(data?.created_at, "lll")}</p>
          </div>
        </Col>

      </Row>
      {/* <Row>
        <Col span={24} md={24}>
          <div className="new_driver_dtl">
            <Row gutter={24}>
              <Col span={24} sm={6} md={8}>
                <p>
                  {lang("Order Id")}:
                </p>
                <p className="font-bold">{data.uid}</p>
              </Col>
              <Col span={24} sm={8} md={8}>
                <p>{lang("Phone Number")}:</p>
                <p className="font-bold">
                  9152333666
                </p>
              </Col>
              <Col span={24} sm={10} md={8}>
                <p>{lang("Email Address")}:</p>
                <p className="font-bold">hiten@yopmail.com</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row> */}
      <Row>

        <div className="driver-personal-dtl">
          <Divider />
          <Steps
            progressDot
            current={0}
            className="driver-dtl-text"
            direction="vertical"
            items={logs.map((item) => ({
              title: `${moment(
                item.created_at,
              ).format("lll")}`,
              description: item.message,
            }))}
          />
        </div>
      </Row>
    </Modal>
  );
};

export default OrderLogs;
