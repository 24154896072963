import { UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Image,
  Input,
  Modal,
  Rate,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import PaymentReceipt from "../../assets/images/paymentreceipt.png";
import Currency from "../../components/Currency";
import apiPath from "../../constants/apiPath";
import { Months } from "../../constants/var";
import { AppStateContext } from "../../context/AppContext";
import { dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import DeleteModal from "../../components/DeleteModal";
import { RejectInvoice } from "../../constants/reasons";
import moment from "moment";
import { DownloadExcel } from "../../components/ExcelFile";

const Search = Input.Search;
const { TabPane } = Tabs;

const ResturantDepositPayment = ({}) => {
  const sectionName = lang("Resturant deposit payment Approval");
  const { country } = useContext(AppStateContext);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState();
  const [detailModal, showDetailModal] = useState(false);
  const [rejectModal, showRejectModal] = useState(false);
  const { request } = useRequest();
  const api = {
    status: apiPath.statusQuote,
    list: apiPath.finance + "/pending-deposit",
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [restaurants, setRestaurant] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    user_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
    // status: 'Pending',
    payment_mod: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const checkFilterValue = () => {
    console.log("filtervalue", filter, filter?.year);
    if (filter.year && filter.month) {
      DownloadExcel(excelData, "deposit payment ");
    } else {
      ShowToast(lang("Please select a year and months"), Severty.ERROR);
    }
  };

  const getFilter = () => {
    request({
      url: `${apiPath.finance}/deposit-filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years, restaurants } = res;
        setCities(data);
        setYears(years);
        setRestaurant(restaurants);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const handleChange = (pagination, sorter, filters) => {
    if (!sorter) {
      fetchData(pagination);
    }
  };

  const fetchData = (pagination, status) => {
    setLoading(true);

    const payload = { ...filter };
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize = pagination ? pagination?.pageSize : 10;

    const queryString = Object.entries(payload)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url: api.list + `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, total, status }) => {
        setLoading(false);
        if (status) {
          setList(data);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    //  setLoading(true);
    fetchData(pagination);
    getFilter();
  }, [refresh, country.country_id, filter]);

  const columns = [
    {
      title: lang("Resturant . ID"),
      render: (_, { user_id }) => `#${user_id ? user_id.uid : ""}`,
    },
    {
      title: lang("NAME"),
      dataIndex: "name",
      key: "name",
      render: (_, { user_id }) => `${user_id?.name}`,
    },
    {
      title: lang("Collection Date"),
      dataIndex: "dateTime",
      key: "dateTime",
      render: (_, { created_at }) => `${dateString(created_at, "ll")}`,
    },
    {
      title: lang("City"),
      dataIndex: "city",
      key: "city",
      render: (_, { city_id }) => `${city_id?.name}`,
    },
    {
      title: lang("Total Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => <Currency price={amount ? amount : 0} />,
    },
    {
      title: lang("Payment Mode"),
      dataIndex: "payment_mode",
      key: "payment_mode",
      render: (_, { payment_mod }) => (payment_mod ? payment_mod : 0),
    },
    {
      title: lang("receipt Image"),
      dataIndex: "receiptImage",
      key: "receiptImage",
      render: (_, { image, name }) => (
        <Image src={image ? image : null} className="table-img image-doc" />
      ),
    },
    {
      title: lang("receipt No."),
      dataIndex: "receiptNo",
      key: "receipt No",
      render: (_, { receipt_number }) => (receipt_number ? receipt_number : 0),
    },
    {
      title: lang("Status"),
      fixed: "right",
      render: (_, { status }) => {
        let color;
        color = "warning";

        if (status === "Approved") {
          color = "green";
        } else if (status === "Rejected") {
          color = "error";
        }

        return <Tag color={color}> {status} </Tag>;
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={lang("View Detail")}
              color={"purple"}
              key={"viewDetail"}
            >
              <Button
                className="ms-sm-2 mt-xs-2 btnStyle btnOutlineDelete"
                onClick={(e) => {
                  showDetailModal(true);
                  setSelected(record);
                }}
              >
                {"View Detail"}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const reject = (selected, reason) => {
    const payload = { reason };
    request({
      url: apiPath.finance + `/deposit-reject/${selected}`,
      method: "POST",
      data: payload,
      onSuccess: ({ data, total, status, message }) => {
        setLoading(false);
        if (status) {
          ShowToast(message, Severty.SUCCESS);
          return;
        }
        ShowToast(message, Severty.ERROR);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const excelData = list.map((row) => ({
    User_id: row?.user_id?.uid || "-",
    Name: row?.user_id?.name || "-",
    Country: row?.country_id?.name || "-",
    City: row?.city_id?.name || "-",
    Total_amount: row?.amount || "-",
    payment_mode: row?.payment_mod || "-",
    receipt_number: row?.receipt_number || "-",
    Status: row?.status || "-",
    Collection_date: row?.created_at
      ? moment(row?.created_at).format("DD-MM-YYYY")
      : "-",
  }));

  return (
    <>
      <div className="tab_inner_tit">
        <div className="tab-upload-wrap d-flex align-items-center justify-content-between">
          <h3>{lang("Resturant Deposit Payment Approval")}</h3>
          <div className="d-flex align-items-center gap-3">
            <div className="city-wrap">
              <Select
                style={{ minWidth: "150px" }}
                placeholder={lang("Driver")}
                showSearch
                value={filter.user_id}
                // filterOption={false}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={restaurants.map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
                onChange={(value) => onChange("user_id", value)}
              />
            </div>

            <div className="city-wrap">
              <Select
                width="250"
                style={{ minWidth: "150px" }}
                placeholder="Status"
                value={filter.status}
                options={[
                  { value: "Pending", label: lang("Pending") },
                  { value: "Approved", label: lang("Approved") },
                  { value: "Rejected", label: lang("Rejected") },
                ]}
                onChange={(value) => onChange("status", value)}
              />
            </div>
            <div className="role-wrap">
              <Select
                last20Years
                width="110px"
                placeholder={lang("City")}
                showSearch
                value={filter.city_id}
                // filterOption={false}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={cities.map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
                //onPopupScroll={handleScroll}
                // onSearch={(newValue) => setSearchCity(newValue)}
                onChange={(value) => onChange("city_id", value)}
              />
            </div>
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Year")}
                showSearch
                value={filter.year}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={years.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(value) => onChange("year", value)}
              />
            </div>
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Month")}
                showSearch
                value={filter.month}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={months.map((item) => ({
                  ...item,
                }))}
                onChange={(value) => onChange("month", value)}
              />
            </div>

            <Button
              onClick={() =>
                setFilter({
                  country_id: undefined,
                  city_id: undefined,
                  year: undefined,
                  month: undefined,
                  status: undefined,
                })
              }
              type="primary"
              icon={<UndoOutlined />}
            >
              {lang("Reset")}
            </Button>

            <div className="btn_grp">
              <Button
                className="primary_btn btnStyle"
                onClick={() => checkFilterValue()}
              >
                {lang("Export to Excel")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive customPagination withOutSearilNo">
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            defaultPageSize: 10,
            responsive: true,
            total: pagination.total,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50"],
          }}
          onChange={handleChange}
          className="ant-border-space"
        />
      </div>

      {detailModal && (
        <DetailsModal
          show={detailModal}
          hide={() => {
            showDetailModal(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
          reject={() => {
            showDetailModal(false);
            showRejectModal(true);
          }}
        />
      )}

      {rejectModal && (
        <DeleteModal
          title={lang(`Reject Receipt`)}
          subtitle={lang(`Are you sure you want to reject this receipt?`)}
          show={rejectModal}
          hide={() => {
            showRejectModal(false);
            setSelected();
          }}
          reasons={RejectInvoice}
          onOk={(message) => reject(selected?._id, message)}
        />
      )}
    </>
  );
};

const DetailsModal = ({ show, hide, data, refresh, reject }) => {
  const { request } = useRequest();

  const [loading, setLoading] = useState(false);

  const Approve = () => {
    setLoading(true);
    const payload = {};
    request({
      url: apiPath.finance + `/deposit-approve/${data._id}`,
      method: "POST",
      onSuccess: ({ data, total, status, message }) => {
        setLoading(false);
        if (status) {
          ShowToast(message, Severty.SUCCESS);
          if (refresh) refresh();

          hide();
          return;
        }
        ShowToast(message, Severty.ERROR);
        hide();
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  return (
    <Modal
      open={show}
      onOk={() => null}
      onCancel={hide}
      width={950}
      okText={lang("Approve")}
      cancelText={lang("Decline")}
      footer={
        data.status != "Approved"
          ? [
              <Button
                key="reject"
                onClick={() => {
                  reject();
                }}
              >
                {lang("Decline")}
              </Button>,
              <Button
                key="approve"
                type="primary"
                onClick={Approve}
                loading={loading}
              >
                {lang("Approve")}
              </Button>,
            ]
          : [
              <Button key="approve" type="primary" onClick={hide}>
                {lang("Okay")}
              </Button>,
            ]
      }
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <h4 className="modal_title_cls">{lang("Driver Details")}</h4>
      <div className="order-head">
        <h4>{data ? data.user_id.name : ""}</h4>
      </div>
      <Row gutter={[45, 0]}>
        <Col span={24} sm={12}>
          <div className="order-dtl-card">
            <div className="order-header">
              <h3>{lang("Deposit Receipt Summary")}</h3>
            </div>
            <div className="customer-dtl">
              <div className="bill-info">
                <h6>{lang("Amount")}</h6>
                <h5>
                  <Currency price={data ? data.amount : 0} />
                </h5>
              </div>
              {/* <div className="bill-info">
                  <h6>Pending Amount:</h6>
                  <h5>AED 00.00</h5>
                </div> */}
            </div>
            {/* <div className="total-price">
                            <div className="bill-info">
                                <h6>TOTAL</h6>
                                <h5><Currency price={data ? data.amount : 0} /></h5>
                            </div>
                        </div> */}
          </div>
          <div className="payment-recepit">
            <h4>{lang("Payment Receipt")}</h4>
            <div className="paymentImg">
              <Image src={data ? data.image : PaymentReceipt} />
            </div>
          </div>
        </Col>
        <Col span={24} sm={12}>
          <div className="order-dtl-card">
            <div className="order-header">
              <h3>{lang("Receipt")}</h3>
            </div>
            <div className="customer-dtl">
              <div className="bill-info">
                <h6>{lang("Date")}</h6>
                <h5>{data ? dateString(data.created_at, "ll") : ""}</h5>
              </div>
              <div className="bill-info">
                <h6>{lang("Payment Mode")}</h6>
                <h5>{data?.payment_mod ?? ""}</h5>
              </div>
              <div className="bill-info">
                <h6>{lang("Payment Status")}</h6>
                <h5>{data?.status ?? ""}</h5>
              </div>
              <div className="bill-info">
                <h6>{lang("Receipt Number")}</h6>
                <h5>{data?.receipt_number ?? ""}</h5>
              </div>
            </div>
          </div>
          {lang("Paid by Self")}
        </Col>
      </Row>
    </Modal>
  );
};

export default ResturantDepositPayment;
