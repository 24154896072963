import {
  Col,
  Image,
  Modal,
  Row,
  Select,
  Skeleton
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";

const View = ({ show, hide, data, }) => {

  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format('MMM D, YYYY');
  };

  useEffect(() => {
    if (!data) return;
    setImage(data.image);
    setMobileNumber({
      mobile_number: data.mobile_number,
      country_code: data.country_code,
    });
  }, [data]);

  return (
    <>
      <Modal
        open={show}
        width={750}
        okText={data ? "Ok" : "Add"}
        onOk={hide}
        onCancel={hide}
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
        }}
        // aria-labelledby="contained-modal-title-vcenter"
        centered
        className="tab_modal"
      >

        <div className="add_user_title">
          <h4 className="modal_title_cls">{`${(data ? "View Broker " : "Add ") + "Details"
            }`}</h4>
        </div>

        {
          !data ? [1, 2, 3, 4].map(item => <Skeleton active key={item} />) :
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <div className="text-center mb-5" onClick={() => setIsModalVisible(true)}>
                  <Image src={data?.image} />
                </div>
              </Col>

              <Col span={24} sm={12}>
                <div className="view-inner-cls broker-d">
                  <h5>Name:</h5>
                  <h6>
                    <span className="cap">{data.name ? data.name : "-"}</span>
                  </h6>
                </div>
              </Col>

              <Col span={24} lg={12} sm={12} className="flagMobileNumber">
                <div className="view-inner-cls broker-d">
                  <h5>Phone Number:</h5>
                  <h6>
                    {data && data?.countryCode && data?.mobileNumber
                      ? data?.countryCode.includes("+")
                        ? data?.countryCode + "-" + data?.mobileNumber
                        : "+" + data?.countryCode + "-" + data?.mobileNumber
                      : "-"}
                  </h6>
                </div>
              </Col>

              <Col span={24} lg={12} sm={12}>
                <div className="view-inner-cls broker-d">
                  <h5>Email Address:</h5>
                  <h6>{data.email ? data?.email : "-"}</h6>
                </div>
              </Col>
              <Col span={24} lg={12} sm={12}>
                <div className="view-inner-cls broker-d">
                  <h5> Birth Date:</h5>
                  <h6>{data?.dob ? data?.dob : "-"}</h6>
                </div>
              </Col>
              <Col span={24} lg={12} sm={12}>
                <div className="view-inner-cls broker-d">
                  <h5>Registered Date:</h5>
                  <h6>{data?.created_at ? formatDate(data.created_at) : '-'}</h6>
                </div>
              </Col>
            </Row>
        }
      </Modal >
    </>
  );
};

export default View;
