let appMode = process.env.REACT_APP_ENV;
let ASSET_URL = "https://inventcolabs.s3.amazonaws.com/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  URL = "https://api.brokerland.co.il/api/";
} else {
  URL = "https://api.brokerland.co.il/api/";
  // URL = "http://localhost:5845/api/";
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "admin/dashboard",

  listOrder: "admin/order/list",
  viewOrder: "admin/order/view",
  statusOrder: "admin/status",

  listTransaction: "admin/transaction/list",
  viewTransaction: "admin/transaction/view",

  // Auth API
  logout: "admin/auth/logout",
  login: "admin/auth/login",

  profile: "admin/auth/get-profile",
  updateProfile: "admin/auth/update-profile",
  changePassword: "admin/auth/change-password",
  updateAppSetting: "admin/auth/update-app-setting",

  forgotPassword: "admin/auth/forgot-password",
  verifyOTP: "admin/auth/verify-otp",
  resetPassword: "admin/auth/reset-password",

  // Asset--->
  listAsset: "admin/assets/list",
  addAsset: "admin/assets/add-asset",
  editAsset: "admin/assets/edit-asset",
  statusChangeAsset: "admin/assets/status-change",
  deleteAsset: "admin/assets/delete-asset",
  //Client
  listClient: "admin/user/clientList",
  // Broker APIs
  listCustomer: "admin/user/list",
  addEditCustomer: "admin/user/add-user",
  deleteCustomer: "admin/user/delete-account",
  statusCustomer: "admin/user/status-change",
  viewCustomer: "admin/customer/view",
  importCustomer: "admin/customer/import-file",
  update: "admin/user/edit-user",

  // SubAdmin APIs
  subAdmin: "admin/sub-admin",
  addEditSubAdmin: "admin/sub-admin",
  statusSubAdmin: "admin/sub-admin/status",
  viewSubAdmin: "admin/sub-admin/view",
  getModule: "admin/sub-admin/module-list",
  addPermission: "admin/sub-admin/add-permission",

  //collector
  collector: "admin/collector",
  location: "admin/service-location",

  // driver APIs
  driver: "admin/driver",
  importDealer: "admin/dealer/import-file",

  checkCode: "admin/discount/check-code",
  discount: "admin/discount",
  revenue: "admin/revenue",
  report: "admin/report",
  request: "admin/request",

  banner: "admin/banner",
  history: "admin/delivery-history",
  adminCommon: "admin/common",

  // Content APIs
  content: "admin/content/list",
  updateContent: "admin/content/edit-content-new",
  notification: "admin/notification",
  rating: "admin/rating",

  finance: "admin/finance",

  // EmailTemplate APIs
  listEmailTemplate: "admin/email-template/list",
  addEditEmailTemplate: "admin/email-template/edit-email-template",
  statusEmailTemplate: "admin/email-template/status",
  viewEmailTemplate: "admin/email-template/view",

  // Blog APIs
  listBlog: "admin/blog/list",
  addEditBlog: "admin/blog/add-edit",
  statusBlog: "admin/blog/status",
  viewBlog: "admin/blog/view",

  //category
  listCategory: "admin/category",
  statusCategory: "admin/category/status",

  //Subscription
  addSubscription: "admin/subscription/add-subscription",
  listSubscription: "admin/subscription/list",
  editSubscription: "admin/subscription/edit-subscription",
  statusChangeSubscription: "admin/subscription/status-change",
  viewSubscription: "admin/subscription/view-subscription",

  // Subscription Order
  listSubscriptionOrder: "admin/subscription-order/list",
  statusChangeSubscriptionOrder: "admin/subscription-order/status-change",

  //company
  company: "admin/company",

  getAppSetting: "common/app-setting",

  // Size APIs
  size: "admin/size",

  //order
  order: "admin/order",

  //log
  log: "admin/activity-log",

  // delivery charge
  deliveryCharge: "admin/delivery-charge",

  //Vendor apis

  common: {
    restaurantCategories: "categories",
    foodCategories: "food-categories",
    countries: "",
    foodItems: "common/food-items",
    countries: "common/country",
    cities: "common/city",
    state: "common/state/",
  },

  // Auth API
  logout: "admin/auth/logout",
  signUp: "vendor/auth/sign-up",
};

export default apiPath;
