import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";

import apiPath from "../constants/apiPath";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import { Bar, Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
);

export const options = {
  type: "line",
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Sales Report",
    },
  },
};

const labels = ["Q1", "Q2", "Q3", "Q4"];
const barChartData = {
  labels: ["Q1", "Q2", "Q3", "Q4"],
  datasets: [
    {
      label: "Sales",
      data: [1, 0, 30, 50, 20, 40, 10, 0],
      backgroundColor: "rgba(52, 122, 226, 0.16)",
      borderColor: "rgba(52, 122, 226, 0.16)",
      borderWidth: 1,
    },
  ],
};

export const DeliveryQuarterlyChart = ({ year }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { request } = useRequest();

  const barChartData = {
    labels: labels,
    datasets: [
      {
        label: "Sales",
        data: data,
        backgroundColor: "rgba(52, 122, 226, 0.16)",
        borderColor: "red",
        borderWidth: 1,
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };
  const fetchData = () => {
    request({
      url: `${apiPath.report}/delivery-discount?period=quarterly${
        year ? `&year=${year}` : ``
      }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        console.log(data, "QuarterlyChart");
        if (status) {
          if (status) {
            const arr = [0, 0, 0, 0, 0, 0];
            data.sales.map(({ interval, count }) => {
              const findIndex = labels.findIndex(
                (item) => item.toLowerCase() == interval.toLowerCase(),
              );
              if (findIndex != -1) {
                arr[findIndex] = count;
              } else {
                arr[findIndex] = 0;
              }
            });
            console.log(arr, "llll");
            setData(arr);
          }
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [year]);
  return (
    <div>
      <Line options={options} data={barChartData} />
    </div>
  );
};

export const PickUpQuarterlyChart = ({ year }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { request } = useRequest();
  const barChartData = {
    labels: labels,
    datasets: [
      {
        label: "Sales",
        data: data,
        backgroundColor: "rgba(52, 122, 226, 0.16)",
        borderColor: "red",
        borderWidth: 1,
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };

  const fetchData = () => {
    request({
      url: `${apiPath.report}/vendor-discount?period=quarterly${
        year ? `&year=${year}` : ``
      }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        console.log(data, "QuarterlyChart");
        if (status) {
          if (status) {
            const arr = [0, 0, 0, 0, 0, 0];
            data.sales.map(({ interval, count }) => {
              const findIndex = labels.findIndex(
                (item) => item.toLowerCase() == interval.toLowerCase(),
              );
              if (findIndex != -1) {
                arr[findIndex] = count;
              } else {
                arr[findIndex] = 0;
              }
            });
            console.log(arr, "llll");
            setData(arr);
          }
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [year]);
  return (
    <div>
      <Line options={options} data={barChartData} />
    </div>
  );
};

export default DeliveryQuarterlyChart;
