export const s3Config = (type) => ({
  dirName: "brokerland/" + type,
  bucketName: "sugamaya",
  region: "us-east-1",
  accessKeyId: "AKIA6GBMIAPJULCPWKU5",
  secretAccessKey: "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",
});

// export const s3Config = (type) => ({
//   dirName: "tawasi/" + type,
//   bucketName: "inventcolabs",
//   region: "ap-south-1",
//   accessKeyId: "AKIAXTJNUUWFIPVPT4U7",
//   secretAccessKey: "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",
// });
