import { UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Select,
  Table,
  Tooltip,
  Col,
  Row,
  Tabs,
  Image,
  Card,
  Tag,
  Avatar,
  Input,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

import EditIcon from "../../../assets/images/edit.png";
import deleteWhiteIcon from "../../../assets/images/icon/deleteWhiteIcon.png";
import Plus from "../../../assets/images/plus.svg";
import ConfirmationBox from "../../../components/ConfirmationBox";
import DeleteModal from "../../../components/DeleteModal";
import SectionWrapper from "../../../components/SectionWrapper";
import ViewLogs from "../../../components/ViewLogs";
import apiPath from "../../../constants/apiPath";
import {
  BlockCustomerReasons,
  DeleteCustomerReasons,
} from "../../../constants/reasons";
import { Months } from "../../../constants/var";
import { AppStateContext, useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import { Severty, ShowToast } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import AddFrom from "./AddFrom";
import { ApproveStatus } from "../../Driver/_ChangeRequest";
import notfound from "../../../assets/images/not_found.png";
import { useParams } from "react-router";
import ChangeModal from "./_ChangeModal";
import View from "./View";
const { TabPane } = Tabs;

function Index() {
  const heading = lang("Broker") + " " + lang("Management");
  const { setPageHeading } = useContext(AppStateContext);
  const { country } = useAppContext();

  const sectionName = lang("Customer");
  const routeName = "customer";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");
  const year = urlParams.get("year");

  const api = {
    status: apiPath.statusCustomer,
    add: apiPath.addEditCustomer,
    list: apiPath.listCustomer,
    delete: apiPath.deleteCustomer,
    update: apiPath.update,
  };

  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [selected, setSelected] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const { confirm } = Modal;

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: year,
    month: undefined,
  });

  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("all");

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const onDelete = (id) => {
    request({
      url: api.delete + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: api.list + "/" + id + "/status",
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => {
        return !image ? (
          <>
            <Avatar
              style={{ backgroundColor: "#00a2ae", verticalAlign: "middle" }}
              className="cap"
              size={40}
            ></Avatar>
          </>
        ) : (
          <>
            <Image className="image-index-radius" src={image} />
          </>
        );
      },
    },
    {
      title: lang("Name"),
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (_, { name }) => {
        return name ? name : "-";
      },
    },
    {
      title: lang("Date Of Birth"),
      dataIndex: "dob",
      key: "dob",
      width: 200,
      render: (_, { dob }) => {
        return dob ? moment(dob).format("ll") : "-";
      },
    },
    {
      title: lang("Gender"),
      dataIndex: "gender",
      key: "gender",
      render: (_, { gender }) => {
        return gender
          ? gender == "F"
            ? "Female"
            : gender == "M"
            ? "Male"
            : "Others"
          : "-";
      },
    },
    {
      title: "Phone Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (_, { mobileNumber, countryCode }) => {
        return (
          (countryCode ? "+" + countryCode + "-" : "+965") +
          (mobileNumber ? mobileNumber : "")
        );
      },
    },
    {
      title: lang("Email ID"),
      dataIndex: "email",
      key: "email",
      render: (_, { email }) => {
        return email ? (
          <span style={{ textTransform: "lowercase" }}>{email}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? "green" : "red";
        return (
          <a>
            <Tag
              onClick={(e) =>
                showConfirm({
                  record: _id,
                  path: api.status,
                  onLoading: () => setLoading(true),
                  onSuccess: () => setRefresh((prev) => !prev),
                })
              }
              color={color}
              key={is_active}
            >
              {is_active ? "Active" : "Inactive"}
            </Tag>
          </a>
        );
      },
    },
    {
      title: lang("Register Date"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: "Action",
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip
              title={lang("Edit")}
              color={"purple"}
              key={"update" + routeName}
            >
              <Button
                title="Edit"
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelected(record);
                  setVisible(true);
                }}
              >
                <img src={EditIcon} />
                {/* <span>{lang("Edit")}</span> */}
              </Button>
            </Tooltip>

            <Tooltip title={"View"} color={"purple"} key={"View"}>
              <Button
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelected(record);
                  setDetailsVisible(true);
                }}
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="eye"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
                </svg>
              </Button>
            </Tooltip>

            <Tooltip title={"Delete"} color={"purple"} key={"Delete"}>
              <Button
                title="Delete"
                className="btnStyle deleteDangerbtn"
                onClick={() => {
                  setSelected(record);
                  setShowDelete(true);
                }}
              >
                <img src={deleteWhiteIcon} />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
    getFilter();
  }, [refresh, debouncedSearchText, filter, country?.country_id]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url:
        api.list +
        `?page=${pagination ? pagination.current : 1}&pageSize=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}${
          path ? `&status=1` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        console.log(data, total);
        setLoading(false);
        if (status) {
          console.log(data, "data");
          setList(data.list.docs);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: data.list.totalDocs,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const handleTabChange = (status) => {
    setSelectedTab(status);
    // fetchData(pagination, '', status)
  };
  const onSearch = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace">
              {/* <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              > */}
              {/* <TabPane tab={lang("Customers LIST")} key="all"> */}
              <SectionWrapper
                cardHeading={lang("Customers") + " " + lang("List")}
                extra={
                  <>
                    <div className="w-100 d-flex align-items-baseline text-head_right_cont">
                      <div className="pageHeadingSearch">
                        <Input.Search
                          className="searchInput"
                          placeholder={lang("Search by  name")}
                          onChange={onSearch}
                          value={searchText}
                        />
                      </div>
                      <Button
                        onClick={() => setSearchText("")}
                        type="primary"
                        icon={<UndoOutlined />}
                      >
                        {lang("Reset")}
                      </Button>
                      <Button
                        className="primary_btn btnStyle"
                        onClick={(e) => {
                          setVisible(true);
                          setSearchText("");
                        }}
                      >
                        <span className="add-Ic">
                          <img src={Plus} />
                        </span>
                        {lang("Add")} {sectionName}
                      </Button>
                    </div>
                  </>
                }
              >
                <div className="table-responsive customPagination">
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                      defaultPageSize: 10,
                      responsive: true,
                      total: pagination.total,
                      // showSizeChanger: true,
                      // showQuickJumper: true,
                      // pageSizeOptions: ["10", "20", "30", "50"],
                    }}
                    onChange={handleChange}
                    className="ant-border-space"
                  />
                </div>
              </SectionWrapper>
              {/* </TabPane> */}
              {/* </Tabs> */}
            </Card>
          </Col>
        </Row>
      </div>

      {visible && (
        <AddFrom
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {detailsVisible && (
        <View
          section={sectionName}
          api={api}
          show={detailsVisible}
          hide={() => {
            setSelected();
            setDetailsVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {showDelete && (
        <DeleteModal
          title={lang("Delete Broker")}
          subtitle={lang(`Are you sure you want to Delete this broker?`)}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
          // reasons={DeleteCustomerReasons}
        />
      )}

      {showStatus && (
        <DeleteModal
          title={`${
            selected?.is_active ? lang(`Block`) : lang(`UnBlock`)
          } ${lang("User")}`}
          subtitle={`${lang("Are you sure you want to")} ${
            selected?.is_active ? lang(`block`) : lang(`unblock`)
          } ${lang("this user?")}`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          onOk={(message) => handleChangeStatus(selected?._id, message)}
          reasons={selected?.is_active ? BlockCustomerReasons : []}
        />
      )}

      {logs && (
        <ViewLogs
          data={selected}
          show={logs}
          hide={() => {
            showLogs(false);
            setSelected();
          }}
        />
      )}
    </>
  );
}

const Request = () => {
  const { country } = useContext(AppStateContext);

  const params = useParams();
  const api = {
    list: apiPath.request,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [refresh, setRefresh] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);

  const [selectedDriver, setSelectedDriver] = useState();

  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const getFilter = () => {
    request({
      url: `${api.list}/filters/Customer`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleChangeStatus = (id) => {
    request({
      url: api.status + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh(true);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const approvalColumns = [
    {
      title: lang("Customer ID"),
      dataIndex: "index",
      key: "index",
      width: 200,
      render: (_, { user_id }) =>
        user_id ? <span className="cap">#{user_id.uid}</span> : "-",
    },
    {
      title: lang("Customer name"),
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (_, { user_id }) => (
        <>
          <Image
            width={40}
            height={40}
            src={user_id.image ? user_id.image : notfound}
            className="table-img "
          />
          {user_id.name ? <span className="cap">{user_id.name}</span> : "-"}
        </>
      ),
    },
    {
      title: lang("Country"),
      dataIndex: "country_id",
      key: "country_id",
      render: (_, { country_id }) => {
        return country_id ? (
          <span className="cap">{country_id.name}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("City"),
      dataIndex: "city_id",
      key: "city_id",
      render: (_, { city_id }) => {
        return city_id ? <span className="cap">{city_id.name}</span> : "-";
      },
    },
    {
      title: lang("Change"),
      dataIndex: "city_id",
      key: "type",
      render: (_, { type }) => {
        return type ? <span className="cap">{type}</span> : "-";
      },
    },

    {
      title: lang("New Value"),
      dataIndex: "city_id",
      key: "type",
      render: (_, { type, email, mobile_number, countryCode, city_id }) => {
        return type ? (
          type == "Email" ? (
            <span className="cap">{email}</span>
          ) : type == "City" ? (
            <span className="cap">{city_id.name}</span>
          ) : type == "Mobile" ? (
            <span className="cap">
              {" "}
              +{countryCode}-{mobile_number}
            </span>
          ) : (
            ""
          )
        ) : (
          "-"
        );
      },
    },

    {
      title: lang("Request date"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record, { approve_status }) => {
        return (
          <>
            {record.status === ApproveStatus.PENDING ? (
              <Tooltip
                title={lang("Waiting for Approval")}
                color={"purple"}
                key={"Edit"}
              >
                <Button
                  title="Edit"
                  className="btnStyle btnOutlineDelete"
                  onClick={() => {
                    setSelectedDriver(record);
                    setVisible(true);
                  }}
                >
                  {lang("Waiting for Approval")}
                </Button>
              </Tooltip>
            ) : record.status === ApproveStatus.ACCEPT ? (
              <Tooltip
                title={lang("Approval Accepted")}
                color={"purple"}
                key="Approval Accepted"
              >
                <Button title="Edit" className="btnStyle btnOutlineDelete">
                  {lang("Accepted")}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                title={lang("Approval Rejected")}
                color={"purple"}
                key="Approval Rejected"
                onClick={() => {
                  setSelectedDriver(record);
                  setVisible(true);
                }}
              >
                <Button title="Edit" className="block-cls cap">
                  {lang("Rejected")}
                </Button>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
    getFilter();
  }, [refresh, debouncedSearchText, filter, country.country_id]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url:
        `${api.list}/Customer` +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${pagination.pageSize ?? 10}&search=${debouncedSearchText}${
          queryString ? `&${queryString}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang(`Customer Change Request List`)}
        cardSubheading={""}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <div className="role-wrap">
                <Select
                  last20Years
                  width="110px"
                  placeholder={lang("City")}
                  showSearch
                  value={filter.city_id}
                  //filterOption={false}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  //onPopupScroll={handleScroll}
                  // onSearch={(newValue) => setSearchCity(newValue)}
                  onChange={(value) => onChange("city_id", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Year")}
                  showSearch
                  value={filter.year}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={years.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => onChange("year", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Month")}
                  showSearch
                  value={filter.month}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={months.map((item) => ({
                    ...item,
                  }))}
                  onChange={(value) => onChange("month", value)}
                />
              </div>

              <Button
                onClick={() =>
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                  })
                }
                type="primary"
                icon={<UndoOutlined />}
              >
                {lang("Reset")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={approvalColumns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>

        {visible && (
          <ChangeModal
            section={lang("Driver")}
            api={api}
            show={visible}
            hide={() => setVisible(false)}
            data={selectedDriver}
            refresh={() => setRefresh(!refresh)}
          />
        )}
      </SectionWrapper>
    </>
  );
};

export default Index;
