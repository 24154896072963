import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

import { Bar, Line } from "react-chartjs-2";
import React, { useEffect, useState } from "react";

import apiPath from "../constants/apiPath";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
);

const labels = [
  "Jan",
  "Fab",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const options = {
  type: "line",
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Discount Report",
    },
  },
};

const barChartData = {
  labels: ["Jan", "Fab", "Mar", "Apr", "May", "Jun", "Jul"],
  datasets: [
    {
      label: "Sales",
      data: [1, 0, 30, 50, 20, 40, 10, 0],
      backgroundColor: "rgba(52, 122, 226, 0.16)",
      borderColor: "red",
      borderWidth: 1,
      pointStyle: "circle",
      pointRadius: 10,
      pointHoverRadius: 15,
    },
  ],
};

export const DeliveryMonthlyChart = ({ year }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const { request } = useRequest();

  const fetchData = () => {
    request({
      url: `${apiPath.report}/delivery-discount?period=monthly${
        year ? `&year=${year}` : ``
      }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        if (status) {
          let datasets = labels.map((ff, index) => {
            const find = data.sales.find(
              (item) => parseInt(item.interval) - 1 == index,
            );
            if (find) {
              return find.count;
            }
            return null;
          });

          const barChartData = {
            labels,
            datasets: [
              {
                label: "Sales",
                data: datasets,
                backgroundColor: "rgba(52, 122, 226, 0.16)",
                borderColor: "red",
                borderWidth: 1,
                pointStyle: "circle",
                pointRadius: 10,
                pointHoverRadius: 15,
              },
            ],
          };

          setData(barChartData);
        }
        setLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [year]);

  return (
    <div>
      <Line
        borderColor="#1EB564"
        options={options}
        data={data ?? barChartData}
      />
    </div>
  );
};

export const PickUpMonthlyChart = ({ year }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const { request } = useRequest();

  const fetchData = () => {
    request({
      url: `${apiPath.report}/vendor-discount?period=monthly${
        year ? `&year=${year}` : ``
      }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        if (status) {
          let datasets = labels.map((ff, index) => {
            const find = data.sales.find(
              (item) => parseInt(item.interval) - 1 == index,
            );
            if (find) {
              return find.count;
            }
            return null;
          });

          const barChartData = {
            labels,
            datasets: [
              {
                label: "Sales",
                data: datasets,
                backgroundColor: "rgba(52, 122, 226, 0.16)",
                borderColor: "red",
                borderWidth: 1,
                pointStyle: "circle",
                pointRadius: 10,
                pointHoverRadius: 15,
              },
            ],
          };

          setData(barChartData);
        }
        setLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [year]);

  return (
    <div>
      <Line
        borderColor="#1EB564"
        options={options}
        data={data ?? barChartData}
      />
    </div>
  );
};

export default DeliveryMonthlyChart;
