import { Button, Table, Tooltip, Select, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";

import Plus from "../../../assets/images/plus.svg";
import DeleteModal from "../../../components/DeleteModal";
import SectionWrapper from "../../../components/SectionWrapper";
import apiPath from "../../../constants/apiPath";
import { AppStateContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import { Severty, ShowToast } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import AddForm from "./AddForm";
import { UndoOutlined } from "@ant-design/icons";
import { BlockSubAdmin, rolesOptions } from "../../../constants/var";
// import CheckIcon from "../../assets/images/check.svg"
import Lottie from "react-lottie";
import ViewLogs from "../../../components/ViewLogs";
import * as success from "../../../assets/animation/success.json";
import { DeleteSubadmin } from "../../../constants/reasons";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function Index() {
  const { setPageHeading, country } = useContext(AppStateContext);

  const sectionName = lang("Sub Admin");
  const routeName = "sub-admin";

  const api = {
    status: apiPath.statusSubAdmin,
    addEdit: apiPath.addEditSubAdmin,
    subAdmin: apiPath.subAdmin,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [selected, setSelected] = useState();
  const [email, setEmail] = useState();
  const [endDate, setEndDate] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });
  const [page, setPage] = useState(1);

  const getFilter = () => {
    request({
      url: `${api.subAdmin}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const columns = [
    {
      title: lang("S. No"),
      dataIndex: "index",
      key: "index",
      render: (value, item, index) =>
        `${
          pagination.current === 1
            ? index + 1
            : (pagination.current - 1) * 10 + (index + 1)
        }`,
    },
    {
      title: lang("Sub-Admin User"),
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return name;
      },
    },

    {
      title: lang("Email"),
      dataIndex: "email",
      key: "email",
      render: (_, { email }) => {
        return email ? (
          <span style={{ textTransform: "lowercase" }}>{email}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("City"),
      render: (_, { city_id }) => {
        return city_id ? city_id.map((item) => item.name).join(",") : "-";
      },
    },
    {
      title: lang("Collector"),
      render: (_, { is_collector }) => {
        return is_collector ? "Yes" : "No";
      },
    },
    {
      title: lang("Role"),
      render: (_, { title }) => {
        return title ? title : "-";
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            {record.is_active ? (
              <>
                <Tooltip
                  title={lang("Edit")}
                  color={"purple"}
                  key={"update" + routeName}
                >
                  <Button
                    title="Edit"
                    className="edit-cl primary_btn"
                    onClick={() => {
                      setSelected(record);
                      setVisible(true);
                    }}
                  >
                    <i class="fa fa-light fa-pen"></i>
                    <span>{lang("Edit")}</span>
                  </Button>
                </Tooltip>

                <Tooltip
                  title={lang("Delete")}
                  color={"purple"}
                  key={"delete" + routeName}
                >
                  <Button
                    className="delete-cls"
                    title={lang("Delete")}
                    onClick={() => {
                      setSelected(record);
                      setShowDelete(true);
                    }}
                  >
                    <i class="fa fa-light fa-trash"></i>
                    <span>{lang("Delete")}</span>
                  </Button>
                </Tooltip>

                <Tooltip
                  title={lang("Block")}
                  color={"purple"}
                  key={"Block" + routeName}
                >
                  <Button
                    className="block-cls"
                    title={lang("Block")}
                    onClick={() => {
                      setSelected(record);
                      setShowStatus(true);
                    }}
                  >
                    <span>{lang("Block")}</span>
                  </Button>
                </Tooltip>
              </>
            ) : (
              <Tooltip
                title={lang("UnBlock")}
                color={"purple"}
                key={"Block" + routeName}
              >
                <Button
                  className="block-cls"
                  title={lang("UnBlock")}
                  onClick={() => {
                    setSelected(record);
                    setShowStatus(true);
                  }}
                >
                  <span>{lang("UnBlock")}</span>
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title={lang("View")}
              color={"purple"}
              key={"View" + routeName}
            >
              <Button
                title={lang("View")}
                className="btnStyle btnOutlineDelete"
                onClick={() => {
                  setSelected(record);
                  showLogs(true);
                }}
              >
                {lang("View")}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const onDelete = (id) => {
    request({
      url: api.subAdmin + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: api.subAdmin + "/" + id + "/status",
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast(data.message, Severty.SUCCESS);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.subAdmin +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${
          pagination && pagination.pageSize ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);

        setList(data);
        console.log(total, "total");
        setPagination((prev) => ({
          ...prev,
          current: pagination.current,
          total: total,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
    getFilter();
  }, [refresh, debouncedSearchText, filter, country?.country_id]);

  useEffect(() => {
    setPageHeading(`${lang("Sub-Admin")} ${lang("Management")}`);
  }, []);

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Sub-Admin") + " " + lang("List")}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("City")}
                  showSearch
                  value={filter.city_id}
                  //  filterOption={false}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  onChange={(value) => onChange("city_id", value)}
                />
              </div>

              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder="Role"
                  value={filter.role}
                  filterOption={false}
                  options={rolesOptions.map((item) => ({
                    value: item.name,
                    label: item.label,
                  }))}
                  onChange={(value) => onChange("role", value)}
                />
              </div>

              <Button
                onClick={() =>
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                  })
                }
                type="primary"
                icon={<UndoOutlined />}
              >
                {lang("Reset")}
              </Button>
              <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                  setSearchText("");
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                {lang("Add")} {sectionName}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          selected={selected}
          refresh={() => setRefresh((prev) => !prev)}
          setEmail={(data) => {
            setEmail(data);
            setShowLink(true);
          }}
        />
      )}

      {showDelete && (
        <DeleteModal
          title={lang("Delete Sub Admin")}
          subtitle={lang(`Are you sure you want to Delete this sub admin?`)}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
          reasons={DeleteSubadmin}
        />
      )}

      {showStatus && (
        <DeleteModal
          title={`${
            selected?.is_active ? lang(`Block`) : lang(`UnBlock`)
          } ${lang("Sub Admin")}`}
          subtitle={`${lang("Are you sure you want to")} ${
            selected?.is_active ? lang(`block`) : lang(`unblock`)
          } ${lang("this sub admin?")}`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          reasons={selected?.is_active ? BlockSubAdmin : []}
          onOk={(message) => handleChangeStatus(selected?._id, message)}
        />
      )}

      {logs && (
        <ViewLogs
          data={selected}
          show={logs}
          hide={() => {
            showLogs(false);
            setSelected();
          }}
        />
      )}

      {showLink && (
        <Modal
          width={750}
          open={showLink}
          onOk={() => {}}
          onCancel={() => setShowLink(false)}
          centered
          className="tab_modal deleteWarningModal"
          footer={null}
        >
          <Lottie options={defaultOptions} height={120} width={120} />
          <h4 className="modal_title_cls mb-2 mt-3">{`Link Send Successfully`}</h4>
          <p className="modal_link_inner mb-0 mt-3">
            Link sent to your email address{" "}
            <span>
              {email?.slice(0, 4) + "XXXX" + email?.slice(email?.indexOf("@"))}.
            </span>
          </p>
          <p className="modal_link_inner">{`Please check email and set your password.`}</p>

          <div className="d-flex align-items-center gap-3 justify-content-center mt-5">
            <Button
              onClick={() => {
                setRefresh((prev) => !prev);
                setShowLink(false);
              }}
              className="primary_btn btnStyle "
            >
              Okay
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default Index;
