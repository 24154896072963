import { PlusCircleOutlined, UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Select,
  Table,
  Tooltip,
  Col,
  Row,
  Tabs,
  Image,
  Card,
  Tag,
  Menu,
  Dropdown,
  Space,
  Input,
  Upload,
  Typography,
  Switch,
  Form,
} from "antd";
import {
  UnorderedListOutlined,
  IdcardOutlined,
  CloseOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import moment from "moment";

import React, { useContext, useEffect, useRef, useState } from "react";

import Breadcrumb from "react-bootstrap/Breadcrumb";

import Avatar from "../../../assets/images/avatar-1.png";
import ConfirmationBox from "../../../components/ConfirmationBox";
import DeleteModal from "../../../components/DeleteModal";
import ViewLogs from "../../../components/ViewLogs";
import apiPath from "../../../constants/apiPath";
import sampleFile from "../../../assets/images/sample.xlsx";
import { DownloadExcel } from "../../../components/ExcelFile";
import * as XLSX from "xlsx";

import {
  BlockCustomerReasons,
  DeleteCustomerReasons,
} from "../../../constants/reasons";
import { Months } from "../../../constants/var";
import { AppStateContext, useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import { Severty, ShowToast } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import notfound from "../../../assets/images/not_found.png";
import { useNavigate, useParams } from "react-router";
import View from "./View";
import PhoneInput from "react-phone-input-2";

// import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
// import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
// import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
// import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
// import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
// import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
// import BedOutlinedIcon from "@mui/icons-material/BedOutlined";

const { Dragger } = Upload;
const { Title } = Typography;
// const options = [];
// for (let i = 0; i < 100000; i++) {
//   const value = `${i.toString(36)}${i}`;
//   options.push({
//     label: value,
//     value,
//     disabled: i === 10,
//   });
// }

const options = [
  {
    text: "Buyer",
    value: "buyer",
  },
  {
    text: "Seller",
    value: "seller",
  },
  {
    text: "Renting",
    value: "renting",
  },
  {
    text: "Renter",
    value: "renter",
  },
];
const filterOP = [
  {
    text: "New",
    value: "new",
  },
  {
    text: "First Contact",
    value: "first_contact",
  },
  {
    text: "In Progress",
    value: "in_progress",
  },
  {
    text: "Active Client",
    value: "active_client",
  },
  {
    text: "Not Relevant",
    value: "not_relevant",
  },
];
const filterplt = [
  {
    text: "Residential",
    value: "residential",
  },
  {
    text: "Commercial",
    value: "commercial",
  },
];
const filtercl = [
  {
    text: "Client",
    value: "client",
  },
  {
    text: "Lead",
    value: "lead",
  },
];

const filterat = [
  {
    text: "Real Estate",
    value: "real estate",
  },
  {
    text: "Personal Property",
    value: "personal property",
  },
];

const filterpt = [
  {
    text: "House",
    value: "house",
  },
  {
    text: "Apartment",
    value: "apartment",
  },
  {
    text: "Villa",
    value: "villa",
  },
];

const filtersn = [
  {
    text: "Whatsapp",
    value: "whatsapp",
  },
  {
    text: "Social Media",
    value: "social media",
  },
  {
    text: "By Phone",
    value: "by phone",
  },
];
const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const { TabPane } = Tabs;

const { Text } = Typography;
const { Search } = Input;

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async (name) => {
    try {
      console.log(record);
      console.log(name);
      // const values = await form.validateFields();
      // toggleEdit();
      // handleSave({
      //   ...record,
      //   ...values,
      // });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <>
        {type === "text" && (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            <Input
              ref={inputRef}
              onChange={(e) => save(e.target.value)}
              // onBlur={save}
              // value={dataIndex}
            />
          </Form.Item>
        )}
        {type === "phone" && (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                validator: (rule, value) => {
                  if (!value) {
                    return Promise.reject(lang("Please enter phone number"));
                  }
                  if (!/^\d{8,15}$/.test(value)) {
                    return Promise.reject(
                      lang("Phone number must be between 8 and 15 digits")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneInput
              inputProps={{
                name: { dataIndex },
                // ["countryCode", "mobileNumber"]
                required: true,
                autoFocus: false,
                autoFormat: false,
                autoComplete: "off",
                ref: { inputRef },
              }}
              isValid={(value, country) => {
                if (value.match(/1234/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else if (value.match(/1234/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else {
                  return true;
                }
              }}
              country={"il"}
              preferredCountries={["il"]}
              onBlur={save}
              onEnterKeyPress={save}
            />
          </Form.Item>
        )}
      </>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

function Index() {
  const items = [
    {
      key: "1",
      label: (
        <button onClick={(e) => onChangeList(false)} className="list-item-btns">
          <IdcardOutlined />
          Card
        </button>
      ),
      onClick: () => handleMenuClick("client"),
    },
    {
      key: "2",
      label: (
        <button onClick={(e) => onChangeList(true)} className="list-item-btns">
          <UnorderedListOutlined />
          List
        </button>
      ),
      onClick: () => handleMenuClick("lead"),
    },
  ];

  const handleMenuClick = (option) => {
    console.log(option);
    // Add your custom logic here
    // fetchData(pagination, { clientType: option });
    setClient(option);
  };

  const heading = lang("My") + " " + lang("Clients");
  const { setPageHeading } = useContext(AppStateContext);
  const { country } = useAppContext();
  const navigate = useNavigate();

  const sectionName = lang("Client");
  const routeName = "client";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");
  const year = urlParams.get("year");

  const api = {
    // status: apiPath.statusClient,
    // uploadClientExcel: apiPath.uploadClientExcel,
    // add: apiPath.addEditClient,
    list: apiPath.listClient,
    // delete: apiPath.deleteClient,
    // update: apiPath.editClient,
    // user: apiPath.deletemultiClient,
  };

  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [selected, setSelected] = useState({ recordId: null });
  const [showMessage, setShowMessage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [deleteAllModal, showDeleteAllModal] = useState(false);

  const [clientCount, setclientCount] = useState(0);
  const [allCount, setallCount] = useState(0);
  const [leadCount, setleadCount] = useState(0);

  const [detailsVisible, setDetailsVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const { confirm } = Modal;

  const [clients, setClients] = useState();
  const [client, setClient] = useState(null);
  const [cities, setCities] = useState([]);
  const [listTrue, setListTrue] = useState(true);

  const [form] = Form.useForm();
  const [error, setError] = useState();
  const [importFile, setImportFile] = useState([]);
  const [file, setFile] = useState([]);
  const fileType = [
    ".csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const handleImport = (info) => {
    const { file, fileList } = info;
    console.log("-----------------:: ", info);
    if (fileList?.length) {
      if (!fileType.includes(file.type)) {
        setError("File format is not correct");
        return false;
      }
      setError();

      const fileData = {
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name,
        uid: file.uid,
        status: "done",
        originFileObj: file.originFileObj,
        size: file.size,
        type: file.type,
        percent: file.percent,
      };

      setFile([fileData]);
      parseExcel(file.originFileObj);
    } else {
      setError("Missing Excel File.");
    }
  };

  const parseExcel = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Parse the worksheet data into an array of objects
      const parsedData = XLSX.utils.sheet_to_json(worksheet);

      // Filter data based on some conditions like below
      const filteredData = parsedData.filter((row) => {
        const email = row.email;
        const country_code = row.countryCode;
        const mobile_number = row.mobileNumber;
        const name = row.name;

        const shouldExclude = () => {
          let exclude = false;

          // Check conditions for name (case-insensitive)
          // if (
          //   name &&
          //   [
          //     "Brand",
          //     "Category",
          //     "Product",
          //     "Service",
          //     "Vehicle Make",
          //     "Vehicle Model",
          //     "Vehicle Type",
          //     "Vehicle Variant",
          //   ]?.includes(sectionName)
          // ) {
          //   const isDuplicateName = existingData?.some(
          //     (existingRow) =>
          //       existingRow?.name?.toLowerCase()?.trim() ===
          //       name?.toLowerCase()?.trim()
          //   );
          //   exclude = exclude || isDuplicateName;
          // }

          // Check conditions for email
          // if (email) {
          //   const isDuplicateEmail = existingData.some(
          //     (existingRow) =>
          //       existingRow.email.toLowerCase() === email.toLowerCase()
          //   );
          //   exclude = exclude || isDuplicateEmail;
          // }

          // // Check conditions for country_code and mobile_number together
          // if (country_code && mobile_number) {
          //   const isMatchingCountryMobile = existingData.some(
          //     (existingRow) =>
          //       existingRow.country_code === country_code &&
          //       existingRow.mobile_number === mobile_number
          //   );
          //   exclude = exclude || isMatchingCountryMobile;
          // }

          return exclude;
        };

        return !shouldExclude();
      });

      // Extract the remaining rows and set to setImportFile state
      setImportFile(filteredData);

      // Excluded rows and download the excel file with name of duplicate
      const excludedRows = parsedData.filter(
        (row) => !filteredData.includes(row)
      );
      if (excludedRows && excludedRows.length > 0) {
        const name = "Duplicate" + "-" + sectionName;
        // DownloadExcel(excludedRows, name)
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: year,
    month: undefined,
  });

  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("client");

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const [optionType, setOptionType] = useState([]);
  const [isType, setIsType] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const debouncedSearchFilter = useDebounce(selectedFilter, 300);

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const onChangeList = (e) => {
    setListTrue(e);
  };

  const onDelete = (id) => {
    if (!id) {
      console.error("No record ID provided for deletion");
      return;
    }
    request({
      url: api.delete + "/" + id,
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: api.list + "/" + id + "/status",
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const defaultColumns = [
    {
      title: lang(" Image"),
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => (
        <>
          <Image
            width={40}
            src={image ? image : Avatar}
            className="table-img"
          />
        </>
      ),
    },
    {
      title: lang("Name"),
      dataIndex: "name",
      key: "name",
      type: "text",
      editable: true,
      width: 200,
      render: (_, { name, last_name, _id }) => {
        return name ? (
          <a
            onClick={() => navigate("/client-detail/" + _id)}
          >{`${name} ${last_name}`}</a>
        ) : (
          "-"
        );

        // return name ? `${name} ${last_name}` : "-";
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: lang("Phone"),
      dataIndex: ["countryCode", "mobileNumber"],
      type: "phone",
      editable: true,
      key: "mobileNumber",
      width: 200,
      render: (_, { countryCode, mobileNumber }) => {
        return `${countryCode ? `+${countryCode}` : ""} ${
          mobileNumber ? mobileNumber : "-"
        }`;
      },
    },
    {
      title: lang("City"),
      dataIndex: "city",
      key: "city",
      width: 200,
      render: (_, { city }) => {
        return city ? city : "-";
      },
    },
    {
      title: "Client Type",
      key: "optionType",
      filters: [
        {
          text: "Buyer",
          value: "buyer",
        },
        {
          text: "Seller",
          value: "seller",
        },
        {
          text: "Renting",
          value: "renting",
        },
        {
          text: "Renter",
          value: "renter",
        },
      ],
      render: (_, { optionType }) => {
        let statusText;
        let color;

        switch (optionType) {
          case "buyer":
            color = "green";
            statusText = "Buyer";
            break;
          case "seller":
            color = "blue";
            statusText = "Seller";
            break;
          case "renting":
            color = "red";
            statusText = "Renting";
            break;
          case "renter":
            color = "orange";
            statusText = "Renter";
            break;
          default:
            color = "olivedrab";
            statusText = "Unknown";
        }
        return (
          <h6>
            <Tag color={color} key={optionType}>
              {statusText ? statusText : "-"}
            </Tag>
          </h6>
        );
      },
    },
    {
      title: lang("Budget"),
      dataIndex: "budget",
      key: "budget",
      width: 200,
      render: (_, { budget }) => {
        return budget
          ? `${new Intl.NumberFormat().format(
              budget[0]
            )} ₪. --- ${new Intl.NumberFormat().format(budget[1])} ₪.`
          : "-";
      },
    },
    {
      title: lang("No of Rooms"),
      dataIndex: "numberOfRooms",
      key: "numberOfRooms",
      width: 200,
      render: (_, { numberOfRooms }) => {
        return numberOfRooms ? numberOfRooms : "-";
      },
    },
    {
      title: "Status",
      key: "is_type",
      filters: [
        {
          text: "New",
          value: "new",
        },
        {
          text: "First Contact",
          value: "first_contact",
        },
        {
          text: "In Progress",
          value: "in_progress",
        },
        {
          text: "Active Client",
          value: "active_client",
        },
        {
          text: "Not Relevant",
          value: "not_relevant",
        },
      ],
      render: (_, { _id, is_type }) => {
        let statusText;

        switch (is_type) {
          case "new":
            statusText = "New";
            break;
          case "first_contact":
            statusText = "First Contact";
            break;
          case "in_progress":
            statusText = "In Progress";
            break;
          case "active_client":
            statusText = "Active Client";
            break;
          case "not_relevant":
            statusText = "Not Relevant";
            break;
          default:
            statusText = "Not Selected";
        }
        return (
          <Select
            style={{ width: "100%" }}
            placeholder={lang(`Select Asset Status`)}
            value={is_type}
            onChange={(value) => handleChangeClientStatus(value, _id)}
            className="status-main-sold"
          >
            <Select.Option value="new">{lang("New")}</Select.Option>
            <Select.Option value="first_contact">
              {lang("First Contact")}
            </Select.Option>
            <Select.Option value="in_progress">
              {lang("In Progress")}
            </Select.Option>
            <Select.Option value="active_client">
              {lang("Active Client")}
            </Select.Option>
            <Select.Option value="not_relevant">
              {lang("Not Relevant")}
            </Select.Option>
          </Select>
        );
      },
    },
  ];
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.type,
      }),
    };
  });

  const handleChangeClientStatus = (value, id) => {
    setLoading(true);
    console.log("Selected status Type: ", value, id);
    request({
      url: api.status + "/" + id,
      method: "PUT",
      data: { is_type: value },
      onSuccess: (data) => {
        setLoading(false);
        ShowToast("Status changed successfully", Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleOptionTypeChange = (value) => {
    console.log(value);
    setOptionType(value);
  };

  const handleIsTypeChange = (value) => {
    console.log(value);
    setIsType(value);
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
    getFilter();
  }, [
    refresh,
    debouncedSearchText,
    filter,
    selectedTab,
    country?.country_id,
    debouncedSearchFilter,
  ]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterIsType = filters ? filters.is_type : null;
    const filterStatus = filters ? filters.optionType : null;
    console.log(debouncedSearchFilter);
    // const filterActive = filters ? filters.clientType : null;
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url:
        api.list +
        `?is_type=${filterIsType ? filterIsType.join(",") : ""}&optionType=${
          filterStatus ? filterStatus.join(",") : ""
        }&page=${pagination ? pagination.current : 1}&pageSize=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}${
          path ? `&status=1` : ""
        }&clientType=${selectedTab}&debouncedSearchFilter=${JSON.stringify(
          debouncedSearchFilter
        )}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data, "data");

          const list = data.list.map((item) => ({ ...item, key: item._id }));

          setClients(list);

          setclientCount(data.clientCount);
          setallCount(data.allCount);
          setleadCount(data.leadCount);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const excelData =
    clients &&
    clients.length > 0 &&
    clients.map((row, index) => ({
      Name: row.name ? row.name : "-",
      mobileNumber: row.mobileNumber ? row.mobileNumber : "-",
      countryCode: row.countryCode ? row.countryCode : "-",
      City: row.city ? row.city : "-",
      Option_Type: row.optionType ? row.optionType : "-",
      budget:
        row?.budget?.length > 0
          ? row.budget[0] + "," + row.budget[1]
          : "0,10000",
      size: row?.size?.length > 0 ? row.size[0] + "," + row.size[1] : "0,10000",
      numberOfRooms: row.numberOfRooms ? row.numberOfRooms : "0",
      Is_Type: row.is_type ? row.is_type : "-",

      Email: row.email ? row.email : "-",
      Neighborhood: row.neighborhood ? row.neighborhood : "-",
      Client_Type: row.clientType ? row.clientType : "-",
      Property_Living_Type: row.propertylivType ? row.propertylivType : "-",
    }));

  const handleOtFilter = (pagination, filters) => {
    filters = {
      is_type: isType,
      optionType: optionType,
    };
    setIsModalOpen(false);
    fetchData(pagination, filters);
  };

  const handleChange = (pagination, filters) => {
    console.log(filters);
    fetchData(pagination, filters);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const DeleteAll = () => {
    if (!hasSelected) return;
    request({
      url: api.user + "/delete-all",
      method: "POST",
      data: {
        ids: selectedRowKeys,
      },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast("Data Deleted Successfully", Severty.SUCCESS);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [uploadModal, setUploadModal] = useState(false);
  const showUploadModal = () => {
    setUploadModal(true);
  };
  const handleOk01 = () => {
    setUploadModal(false);
  };
  const handleCancel01 = () => {
    setUploadModal(false);
  };

  const onImport = () => {
    if (error) {
      return;
    }
    if (file?.length) {
      setLoading(true);
      const payload = {};
      payload.import_file = importFile;
      request({
        url: api.uploadClientExcel,
        method: "POST",
        data: payload,
        onSuccess: (data) => {
          setLoading(false);
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS);
            // hide();
            fetchData({ ...pagination, current: 1 });
            setUploadModal(false);
            // refresh();
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error.response.data.errors[0].message, Severty.ERROR);
          setLoading(false);
        },
      });
    } else {
      setError("Please Select an Ecxel File.");
    }
  };

  const menuItems = [
    // { text: "Name", key: "name", label: "Name" },
    // { text: "Email", key: "email", label: "Email" },
    { text: "City", key: "city", label: "City" },
    // { text: "Client/Lead", key: "client_lead", label: "Client/Lead" },
    { text: "Client Type", key: "clientType", label: "Client Type" },
    // { text: "Status", key: "status", label: "Status" },
    // { text: "Asset Type", key: "assetType", label: "Asset Type" },
    // { text: "Property Type", key: "propertyType", label: "Property Type" },
    // { text: "Source Name", key: "SourceName", label: "Source Name" },
    { text: "No of Rooms", key: "numberOfRooms", label: "No of Rooms" },
    { text: "budget", key: "budget", label: "budget" },
    { text: "size", key: "size", label: "size" },
    // {
    //   text: "Property Living Type",
    //   key: "propertylivType",
    //   label: "Property Living Type",
    // },
  ];

  // const handleMeClick = (e) => {
  //   if (!selectedFilters.includes(e.key)) {
  //     const newSelectedFilters = [...selectedFilters, e.key];
  //     setSelectedFilters(newSelectedFilters);

  //     const filtersObject = newSelectedFilters.reduce((acc, key) => {
  //       acc[key] = null;
  //       return acc;
  //     }, {});

  //     setSelectedFilter(filtersObject);
  //   }
  // };

  // const handleClose = (removedFilter) => {
  //   const newFilters = selectedFilters.filter(
  //     (filter) => filter !== removedFilter
  //   );
  //   setSelectedFilters(newFilters);

  //   const filtersObject = newFilters.reduce((acc, key) => {
  //     acc[key] = null;
  //     return acc;
  //   }, {});
  //   setSelectedFilter(filtersObject);
  // };

  const handleMeClick = (e) => {
    if (!selectedFilters.includes(e.key)) {
      const newSelectedFilters = [...selectedFilters, e.key];
      setSelectedFilters(newSelectedFilters);
      setAddFiltersVisible(true);
      setSelectedFilter((prevSelectedFilter) => ({
        ...prevSelectedFilter,
        [e.key]: null,
      }));
    }
  };

  const handleClose = (removedFilter) => {
    const newFilters = selectedFilters.filter(
      (filter) => filter !== removedFilter
    );
    setSelectedFilters(newFilters);

    setSelectedFilter((prevSelectedFilter) => {
      const { [removedFilter]: _, ...rest } = prevSelectedFilter;
      return rest;
    });
  };

  const menu = (
    <Menu onClick={handleMeClick}>
      {menuItems.map((item) => (
        <Menu.Item text={item.text} key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  const [isToggled, setIsToggled] = useState(false);

  const [addFiltersVisible, setAddFiltersVisible] = useState(false); // State to manage visibility

  const handleToggleFilters = () => {
    setAddFiltersVisible(!addFiltersVisible); // Toggle visibility
    setIsToggled(!isToggled);
  };

  // const handleSetVal = (value) => {
  //   console.log(value);
  // };

  // const menu02 = (value) => (
  //   <div className="menu-items-nav">
  //     <Input
  //       placeholder={value}
  //       value={selectedFilter ? selectedFilter.value : ""}
  //       onChange={handleSetVal(value)}
  //     />
  //   </div>
  // );

  const handleSetVal = (key, value) => {
    console.log(`Key: ${key}, Value: ${value}`);
    console.log(selectedFilter);
    setSelectedFilter((prevSelectedFilter) => ({
      ...prevSelectedFilter,
      [key]: value,
    }));
    setAddFiltersVisible(true);
    // fetchData(pagination, debouncedSearchFilter);
  };

  const changableFeilds = (value) => {
    switch (value) {
      case "city":
        return "City";
      case "clientType":
        return "Client Type";
      case "numberOfRooms":
        return "Rooms";
      case "budget":
        return "Budget";
      case "size":
        return "Size";
      default:
        return value;
    }
  };

  const menu02 = (key) => {
    if (key === "assetType") {
      return (
        <Select
          placeholder="Please select"
          key="optionType"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filterat.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }
    if (key === "propertyType") {
      return (
        <Select
          placeholder="Please select"
          key="optionType"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filterpt.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }
    if (key === "sourceName") {
      return (
        <Select
          placeholder="Please select"
          key="optionType"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filtersn.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }
    if (key === "clientType") {
      return (
        <Select
          placeholder="Please select"
          key="optionType"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={options.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }

    if (key === "client_lead") {
      return (
        <Select
          placeholder="Please select"
          key="client_lead"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filtercl.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }

    if (key === "propertylivType") {
      return (
        <Select
          placeholder="Please select"
          key="propertylivType"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filterplt.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }

    if (key === "status") {
      return (
        <Select
          placeholder="Please select"
          key="status"
          onChange={(selectedOption) => handleSetVal(key, selectedOption)}
          options={filterOP.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
        />
      );
    }

    if (
      key === "city" ||
      key === "email" ||
      key === "name" ||
      key === "numberOfRooms" ||
      key === "budget" ||
      key === "size"
    ) {
      return (
        <div className="menu-items-nav">
          <Input
            placeholder={changableFeilds(key)}
            value={selectedFilter ? selectedFilter[key] : ""}
            onChange={(e) => handleSetVal(key, e.target.value)}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Row className="justify-content-between align-items-center">
        <div className="main-hh-headding">
          <div className="headding-cc">My {sectionName}</div>
          <Breadcrumb className="bread-menu">
            <Breadcrumb.Item className="bread-items" href="#">
              Dashboard
            </Breadcrumb.Item>
            <Breadcrumb.Item active className="bread-items" href="#">
              {sectionName}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Row>
      <Card>
        <Tabs
          defaultActiveKey={selectedTab}
          className="myclients-tab"
          onTabClick={(e) => {
            setSelectedTab(e);
          }}
        >
          <TabPane
            tab={
              <span className="myclients-tab-button">
                Clients ({clientCount})
              </span>
            }
            key="client"
            className="myclients-tab-body"
          ></TabPane>
        </Tabs>
        <Row gutter={[24, 16]}>
          <Col span={24} md={24}>
            <div className="table-responsive customPagination">
              <Table
                loading={loading}
                columns={columns}
                components={components}
                // rowSelection={rowSelection}
                onChange={handleChange}
                dataSource={clients}
                pagination={{
                  defaultPageSize: 10,
                  responsive: true,
                  total: pagination.total,
                }}
                className="ant-border-space"
              />
            </div>
          </Col>
        </Row>
      </Card>

      {detailsVisible && (
        <View
          section={sectionName}
          api={api}
          show={detailsVisible}
          hide={() => {
            setSelected();
            setDetailsVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {showDelete && (
        <DeleteModal
          title={lang("Delete Client")}
          subtitle={lang("Are you sure you want to Delete this Client?")}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected({ recordId: null });
          }}
          onOk={() => onDelete(selected.recordId)}
        />
      )}

      {showStatus && (
        <DeleteModal
          title={`${
            selected?.is_active ? lang(`Block`) : lang(`UnBlock`)
          } ${lang("User")}`}
          subtitle={`${lang("Are you sure you want to")} ${
            selected?.is_active ? lang(`block`) : lang(`unblock`)
          } ${lang("this user?")}`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          onOk={(message) => handleChangeStatus(selected?._id, message)}
          reasons={selected?.is_active ? BlockCustomerReasons : []}
        />
      )}

      {deleteAllModal && (
        <DeleteModal
          title={lang("Delete All Users")}
          subtitle={lang(`Are you sure you want to Delete all Users?`)}
          show={deleteAllModal}
          hide={() => {
            showDeleteAllModal(false);
            // setSelectedIds([]);
            setSelectedRowKeys([]);
          }}
          onOk={() => DeleteAll()}
        />
      )}

      {logs && (
        <ViewLogs
          data={selected}
          show={logs}
          hide={() => {
            showLogs(false);
            setSelected();
          }}
        />
      )}

      {/* <Modal
        title="Filter"
        className="tab_modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form.Item label={"title"} className="mt-3">
          <Select
            mode="multiple"
            placeholder="Please select"
            defaultValue={[]}
            key="optionType"
            onChange={handleChange}
            options={options}
          />
        </Form.Item>
        <Form.Item label={"title"} className="mt-3">
          <Select
            mode="multiple"
            placeholder="Please select"
            defaultValue={[]}
            key="is_type"
            onChange={handleChange}
            options={filterOP}
          />
        </Form.Item>
      </Modal> */}

      <Modal
        title="Filter"
        className="tab_modal"
        open={isModalOpen}
        onOk={handleOtFilter}
        onCancel={handleCancel}
      >
        <Form.Item label={"Client Type"} className="mt-3">
          <Select
            mode="multiple"
            placeholder="Please select"
            defaultValue={[]}
            key="optionType"
            onChange={handleOptionTypeChange}
            options={options.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </Form.Item>
        <Form.Item label={"Status"} className="mt-3">
          <Select
            mode="multiple"
            placeholder="Please select"
            defaultValue={[]}
            key="is_type"
            onChange={handleIsTypeChange}
            options={filterOP.map((option) => ({
              label: option.text,
              value: option.value,
            }))}
          />
        </Form.Item>
      </Modal>

      {/* <Modal
        title="Upload CSV/Excel File"
        className="tab_modal"
        open={uploadModal}
        onOk={handleCsvUpload(e.target.file)}
        onCancel={handleCancel01}
      >
        <div className="dawnload-btn">
          <a href={sampleFile} download="sample.xlsx">
            <Button className="dawn-bt">
              Download Sample <ArrowDownwardOutlinedIcon />
            </Button>
          </a>
        </div>
        <Form.Item label={"Upload"} className="mt-2">
          <Upload
          // beforeUpload={beforeUpload}
          // customRequest={handleCsvUpload}
          >
            <Button className="btn-upload" icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
      </Modal> */}

      <Modal
        open={uploadModal}
        title="Import Excel"
        okText="Import"
        onCancel={handleCancel01}
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
        }}
      >
        <Form id="create" form={form} onFinish={onImport} layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item name="import_file">
                <div className="text-center">
                  <Button
                    title="Download Sample File"
                    href={sampleFile}
                    download="sample.xlsx"
                    // onClick={() => {
                    //   SampleFileDownload(sectionName);
                    //   console.log(sectionName);
                    // }}
                  >
                    <i class="fas fa-download"></i>&nbsp;&nbsp;Download Sample
                    File
                  </Button>
                </div>

                <div className="text-center mt-1 mb-2">
                  <span className="text-danger" style={{ fontWeight: "500" }}>
                    The column names in the sheet should match those given in
                    the sample file.
                  </span>
                </div>

                {/* <Divider orientation="center" orientationMargin={0} className="devider-color">OR</Divider> */}

                <Dragger
                  fileList={file}
                  onRemove={(e) => setFile([])}
                  maxCount={1}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleImport}
                  customRequest={(e) => null}
                >
                  <p className="ant-upload-drag-icon">
                    {" "}
                    <InboxOutlined />{" "}
                  </p>
                  <p className="ant-upload-text">
                    Click or Drag File to This Area to Upload
                  </p>
                  <p className="ant-upload-hint">
                    Please Select an Excel File.
                  </p>
                </Dragger>
                {error && (
                  <div style={{ color: "red", padding: 5 }}>{error}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
export default Index;
