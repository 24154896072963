import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { Severty, ShowToast } from "../../../helper/toast";
import useRequest from "../../../hooks/useRequest";
import UploadImage from "./_UploadImage";
import { useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";

const AddForm = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);

  const { country: mCountry, cities } = useAppContext();

  const handleChange = (value, data) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data.dialCode.length),
    });
  };

  const getCountry = () => {
    request({
      url: `/common/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (status) {
          setCountry(data.data);
        }
      },
    });
  };

  const getCity = (id) => {
    request({
      url: `/country-city/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "city");
        if (status) {
          setCity(data);
        }
      },
    });
  };

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue({
      ...data,
      dob: moment(data.dob),
      mobile: data.countryCode + data.mobileNumber,
    });


    setImage(data.image);
    setMobileNumber({
      mobile_number: data.mobileNumber,
      country_code: data.countryCode,
    });
  }, [data]);

  useEffect(() => {
    if (!mCountry.country_id) return;

    form.setFieldsValue({
      country_id: mCountry.country_id,
    });

    getCity(mCountry.country_id);
  }, [mCountry.country_id]);

  const onCreate = (values) => {
    const {
      name,
      ar_name,
      email,
      dob,
      country,
      city,
      area,
      gender,
      country_id,
      city_id,
    } = values;
    const payload = {};
    setLoading(true);
    payload.name = name;
    payload.dob = dob ? moment(dob).format("YYYY-MM-DD") : null;
    payload.country = country;
    payload.city = city;
    // payload.area = area;
    payload.gender = gender;
    payload.mobile_number = mobileNumber.mobile_number;
    payload.country_code = mobileNumber.country_code;
    payload.ar_name = ar_name;
    payload.email = email;
    payload.country_id = country_id;
    payload.city_id = city_id;
    payload.image = image;
    request({
      url: `${data ? api.update + "/" + data._id : api.add}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      open={show}
      width={750}
      okText={data ? lang("Update") : lang("Add")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        initialValues={{
          // dob: moment("1990-01-01", "YYYY-MM-DD"),
        }}
      >
        <div className="add_user_title">
          <h4 className="modal_title_cls">{`${data
            ? lang("Edit Broker") /* sectionName */
            : lang("Add Broker") /* sectionName */
            }`}</h4>
        </div>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <div className="text-center">
              <Form.Item
                className="upload_wrap"
                rules={[
                  {
                    validator: (_, value) => {
                      if (image) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(lang("Profile image is required")),
                      );
                    },
                  },
                ]}
                name="image"
              >
                <UploadImage value={image} setImage={setImage} />
              </Form.Item>
            </div>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={lang(`Name`)}
              name="name"
              rules={[
                { required: true, message: lang("Please enter the to name") },
                {
                  max: 100,
                  message: lang(
                    "Name should not contain more then 100 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`Enter Name`)} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={lang(`Name Hebrew`)}
              name="ar_name"
              rules={[
                {
                  required: true,
                  message: lang("Please enter the to name in arabic"),
                },
                {
                  max: 100,
                  message: lang(
                    "Name should not contain more then 100 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`أدخل اسم الفئة`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label="Country"
              name="country_id"
              rules={[
                { required: true, message: "Please select the country!" },
              ]}
            >
              <Select
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                autoComplete="off" placeholder="Select Country" showSearch >
                {
                  country.map(item => <Select.Option key={item._id} label={item.name} value={item._id}>{item.name} </Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={lang(`Date Of Birth`)}
              name="dob"
              rules={[
                {
                  required: true,
                  message: lang("Please select the date of birth"),
                },
              ]}
            >
              <DatePicker
                placeholder={lang("Select Date Of Birth")}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={lang("Gender")}
              name="gender"
              rules={[
                { required: true, message: lang("Please select the gender!") },
              ]}
            >
              <Select placeholder={lang("Select Gender")}>
                <Select.Option value="M">{lang("Male")} </Select.Option>
                <Select.Option value="F">{lang("Female")} </Select.Option>
                {/* <Select.Option value="O">Other </Select.Option> */}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12} className="flagMobileNumber">
            <Form.Item
              label={lang("Mobile Number")}
              name="mobile"
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject(lang("Please enter phone number"));
                    }
                    if (!/^\d{8,15}$/.test(value)) {
                      return Promise.reject(
                        lang("Phone number must be between 8 and 12 digits"),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: false,
                  autoFormat: false,
                  autoComplete: "off",
                }}
                isValid={(value, country) => {
                  if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else {
                    return true;
                  }
                }}
                country={"il"}
                preferredCountries={["ps", "il"]}
                // value={
                //   mobileNumber
                //     ? (mobileNumber.country_code
                //         ? mobileNumber.country_code
                //         : "+27") +
                //       (mobileNumber.mobile_number ? mobileNumber.mobile_number : null)
                //     : "+27"
                // }
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item
              label={lang("Email ID")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: lang("The email is not a valid email!"),
                },
                { required: true, message: lang("Please enter the email!") },
                {
                  max: 50,
                  message: lang(
                    "Email should not contain more then 50 characters!",
                  ),
                },
                {
                  min: 5,
                  message: lang("Email should contain at least 5 characters!"),
                },
                {
                  pattern: new RegExp(
                    /^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                  ),
                  message: lang("Enter valid email!"),
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder={lang("Enter Email Address")}
              />
            </Form.Item>
          </Col>

          {/* <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={`Area`}
              name="area"
              rules={[
                {
                  max: 20,
                  message: "area should not contain more then 20 characters!",
                }
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`Enter area`} />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
