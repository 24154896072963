import { UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Switch,
  Table,
  Tabs,
  Tooltip
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Plus from "../../assets/images/plus.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import ViewLogs from "../../components/ViewLogs";
import apiPath from "../../constants/apiPath";
import { DeleteRestaurantReasons } from "../../constants/reasons";
import { BlockRest, Months } from "../../constants/var";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import AddForm from "./AddForm";
import ViewCompany from "./View";

const { TabPane } = Tabs;

function Index() {
  const heading = lang("Company Management");
  const { setPageHeading } = useContext(AppStateContext);

  const [selectedTab, setSelectedTab] = useState("Restaurant_List");

  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const handleTabChange = (status) => {
    setSelectedTab(status);
    // fetchData(pagination, '', status)
  };

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
            {
             /*  <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane tab={lang("Restaurant List")} key="Restaurant_List">
                  <RestaurantList />
                </TabPane>

                <TabPane
                  tab={lang("APPROVAL PENDING")}
                  key={"APPROVAL_PENDING"}
                >
                  <Approval />
                </TabPane>
              </Tabs>
            */ } 
            <RestaurantList />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const RestaurantList = () => {

  const sectionName = lang("Company");
  const routeName = "companies";
  const heading = lang("Company Management");
  const { setPageHeading, country } = useContext(AppStateContext);

  const api = {
    status: apiPath.company + "/status",
    list: apiPath.company,
  };

  const { request } = useRequest();
  const [list, setList] = useState([]);
  const { showConfirm } = ConfirmationBox();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [visible, setVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [viewVisible, setViewVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [searchText, setSearchText] = useState("");
  const [deleteModal, showDeleteModal] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("Restaurant_List");
  const [statusModal, setstatusModal] = useState(false);

  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const { language } = useAppContext();

  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  // useEffect(() => {
  //   getCountry();
  // }, []);

  // useEffect(() => {
  //   if (!country.country_id) return
  //   setCities([])
  //   getCities(country.country_id, 1);
  // }, [country.country_id]);

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id, page, search = false) => {
    if (!id) return;
    request({
      url: `/country-city/${id}?page=${page}&pageSize=200${search ? `&search=${search}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setCities((prev) => [...data]);
          //search ? setCities(prev => ([...data])) : setCities(prev => ([...prev, ...data]));
        }
      },
    });
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const view = (id) => {
    navigate(`/${routeName}/view/${id}`);
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
    getFilter();
  }, [refresh, debouncedSearchText, filter, country.country_id]);

  const columns = [
    {
      title: lang("Name"),
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return name ? <span className="cap">{name}</span> : "-";
      },
    },
    {
      title: lang("Arabic Name"),
      dataIndex: "name",
      key: "name",
      render: (_, { ar_name }) => {
        return ar_name ? <span className="cap">{ar_name}</span> : "-";
      },
    },
    // {
    //   title: lang("Phone Number"),
    //   dataIndex: "mobile_number",
    //   key: "mobile_number",
    //   render: (_, { mobile_number, country_code }) => {
    //     return mobile_number && country_code
    //       ? `(${country_code}) ${mobile_number}`
    //       : "-";
    //   },
    // },
    {
      title: lang("Code"),
      dataIndex: "email",
      key: "email",
      render: (_, { code }) => {
        return code ? code : "-";
      },
    },
    {
      title: lang("Start Date"),
      key: "created_at",
      dataIndex: "start_date",
      render: (_, { start_date }) => {
        return moment(start_date).format("DD-MMM-YYYY");
      },
    },
    // {
    //   title: "Member Type",
    //   dataIndex: "member_type",
    //   key: "member_type",
    //   render: (_, { member_type }) => {
    //     return member_type ? member_type : "Restaurant";
    //   },
    // },
    // {
    //   title: "Country",
    //   dataIndex: "country",
    //   key: "country",
    //   render: (_, { country_id }) => {
    //     return country_id ? country_id.name : "-";
    //   },
    // },
    {
      title: lang("City"),
      dataIndex: "city",
      key: "city",
      render: (_, { city_id }) => {
        const currentLang = language || "en";
        if (currentLang == 'en') {
          return city_id ? city_id.name : "-";
        } else {
          return city_id ? city_id?.ar_name : "-";
        }
      },
    },
    {
      title: lang("Area"),
      dataIndex: "area",
      key: "area",
      render: (_, { area }) => {
        const currentLang = language || "en";
        if (currentLang == 'en') {
          return area ? area?.name : "-";
        } else {
          return area ? area?.ar_name : "-";
        }
      },
    },
    {
      title: lang("Address"),
      dataIndex: "address",
      key: "address",
      width: 50,
      render: (_, { address, ar_address }) => {
        const currentLang = language || "en";
        if (currentLang == 'en') {
          return address ? address : "-";
        } else {
          return address ? ar_address : "-";
        }
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={lang("View")}
              color={"purple"}
              key={"view" + routeName}
            >
              <Button
                className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                onClick={(e) => {
                  setSelected(record);
                  setViewVisible(true);
                }}
              >
                {lang("View")}{" "}
              </Button>
            </Tooltip>
            {/* <Tooltip
              title={lang("Logs")}
              color={"purple"}
              key={"view" + routeName}
            >
              <Button
                className="btnStyle btnOutlineDelete"
                onClick={(e) => {
                  setSelected(record);
                  showLogs(true);
                }}
              >
                {lang("Logs")}{" "}
              </Button>
            </Tooltip> */}
          </>
        );
      },
    },
    {
      title: lang("Status"),
      key: "is_active",
      dataIndex: "is_active",
      render: (_, { _id, is_active }) => {
        return (
          <Switch
            onChange={() => {
              setSelected({ _id, is_active });
              showModal();
              //  handleChangeStatus(_id);
            }}
            checked={is_active}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.list +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${pagination ? pagination.current : 1
        }&search=${debouncedSearchText}&pageSize=${pagination ? pagination.pageSize : 10}${queryString ? `&${queryString}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        console.log(data, "data");
        setList(data);
        setPagination((prev) => ({ ...prev, total: total }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const showModal = () => {
    setstatusModal(true);
  };

  const handleCancel = () => {
    setstatusModal(false);
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: `${api.list}/${id}/status`,
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setSelected();
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onDelete = (id) => {
    request({
      url: api.list + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang(`Company List`)}
        extra={
          <>
            {/* <div className="button_group justify-content-end w-100"> */}
            <div className="w-100 d-flex align-items-baseline text-head_right_cont">
              {/* <div className="city-wrap">
                <Select
                  width="110"
                  value={filter.country_id}
                  placeholder="Country"
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    getCities(value);
                    onChange("country_id", value);
                  }}
                  options={countries.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                />
              </div> */}
              <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={lang(
                    "Search by company name, code ,address",
                  )}
                  onChange={onSearch}
                  allowClear
                />
              </div>
              <div className="role-wrap">
                <Select
                  last20Years
                  width="110px"
                  placeholder={lang("City")}
                  showSearch
                  value={filter.city_id}
                  //filterOption={false}

                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  // onPopupScroll={handleScroll}
                  // onSearch={(newValue) => setSearchCity(newValue)}
                  onChange={(value) => onChange("city_id", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Year")}
                  showSearch
                  value={filter.year}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={years.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => onChange("year", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Month")}
                  showSearch
                  value={filter.month}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={months.map((item) => ({ ...item }))}
                  onChange={(value) => onChange("month", value)}
                />
              </div>
              <Button
                onClick={() =>
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                  })
                }
                type="primary"
                icon={<UndoOutlined />}
              >
                {lang("Reset")}
              </Button>
              <Button
                className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                  // setSearchText("");
                }}
              >
                <span className="add-Ic d-flex">
                  <img src={Plus} />
                </span>
                {lang("Add")} {sectionName}
              </Button>
            </div>
          </>
        }
      >
        {/* <h4 className="text-right mb-1cont-space cont-space">
                {pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}
              </h4> */}
        <div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {viewVisible && (
        <ViewCompany
          section={sectionName}
          api={api}
          show={viewVisible}
          hide={() => {
            setSelected();
            setViewVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
          deleteRestaurant={() => {
            showDeleteModal(true);
            setViewVisible(false);
          }}
          viewLogs={() => {
            showLogs(true);
          }}
        />
      )}

      {logs && (
        <ViewLogs
          data={selected.vendor_id}
          show={logs}
          hide={() => {
            showLogs(false);
            setSelected();
          }}
        />
      )}

      {deleteModal && (
        <DeleteModal
          title={lang("Delete Company")}
          subtitle={lang(`Are you sure you want to Delete this company?`)}
          show={deleteModal}
          hide={() => {
            showDeleteModal(false);
            setSelected();
          }}
          reasons={[]} //DeleteRestaurantReasons
          onOk={() => onDelete(selected?._id)}
        />
      )}

      {statusModal && (
        <DeleteModal
          title={lang("Company Status")}
          subtitle={`${lang(`Are you sure you want to`)} ${selected?.is_active ? lang(`Deactivate`) : lang("Activate")
            } ${lang(`this comapany?`)}`}
          show={statusModal}
          hide={() => {
            handleCancel();
            setSelected();
          }}
          reasons={selected?.is_active ? [] : []} //BlockRest
          onOk={(message) => handleChangeStatus(selected?._id, message)}
        />
      )}
    </>
  );
};


export default Index;
